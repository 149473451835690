<template>
  <div :class="{'map-fullscreen': showFullscreen, 'map-container': !showFullscreen}">
    <div id="map"></div>
    <div id="map-tools" @dblclick="stopClick">
      <div class="box">
        <el-popover trigger="click" placement="left-start" title="搜索位置">
          <div>
            <div style="margin-bottom: 10px">
              <el-radio v-model="searchType" label="1">按关键词搜索</el-radio>
              <el-radio v-model="searchType" label="2">按经纬度坐标搜索</el-radio>
            </div>
            <div style="margin-bottom: 10px">
              <el-autocomplete v-if="searchType=='1'" style="width: 100%" placeholder="请输入地址，限中国境内" clearable
                               v-model="searchText" :fetch-suggestions="queryAddr"
                               @select="handleAddrSelect"/>
              <el-input v-else v-model="searchLngLat" placeholder="例：121.270084,31.752807" clearable>
                <el-button slot="append" icon="el-icon-search" @click="handleSearchLngLat"></el-button>
              </el-input>
            </div>
          </div>
          <div slot="reference">
            <img src="/images/map/search.png">
          </div>
        </el-popover>
      </div>
      <div class="box">
        <img src="/images/map/full.png" @click="showFullscreen = !showFullscreen">
      </div>
      <div class="box">
        <img src="/images/map/layer.png" @click="showLayerBox = !showLayerBox">
        <div v-show="showLayerBox" class="window window-layer">
          <div @click="selectLayer(0)" class="box-layer"><img
              :src="'/images/map/layer-0' + (currentLayer==0?'-s':'') + '.png'"><span>深色地图</span></div>
          <div @click="selectLayer(1)" class="box-layer"><img
              :src="'/images/map/layer-1' + (currentLayer==1?'-s':'') + '.png'"><span>腾讯卫星</span></div>
          <!--<div @click="selectLayer(2)" class="box-layer"><img
              :src="'images/map/layer-1' + (currentLayer==2?'-s':'') + '.png'"><span>天地图卫星</span></div>-->
          <div @click="selectLayer(3)" class="box-layer"><img
              :src="'/images/map/layer-3' + (currentLayer==3?'-s':'') + '.png'"><span>平面地图</span></div>
        </div>
      </div>
<!--      <div class="box" @click="network()">-->
<!--        <img :src="'/images/map/network' + (showNetwork?'-s':'') + '.png'">-->
<!--      </div>-->
      <div class="box" @click="zoomIn()">
        <img src="/images/map/zoomIn.png">
      </div>
      <div class="box" @click="zoomOut()">
        <img src="/images/map/zoomOut.png">
      </div>
    </div>
    <div id="map-drawers" v-if="showDrawers">
      <div class="colors">
        <div class="btn-tool btn-tool-add" v-if="editorMode==1" @click="changeEditMode">
          <div style="display:flex; align-items: center">
            <i class="el-icon-plus"></i>
          </div>
          <div>
            <div>新增模式</div>
            <div class="des">图上点击可新增</div>
          </div>
        </div>
        <div class="btn-tool btn-tool-edit" v-else @click="changeEditMode">
          <div style="display:flex; align-items: center">
            <i class="el-icon-edit-outline"></i>
          </div>
          <div>
            <div>编辑模式</div>
            <div class="des">选中目标可移动删除</div>
          </div>
        </div>
        <div v-if="editorMode==1" style="position:relative; height: 25px">
          <div @click="selectColor(i)" v-for="(item,i) in editorColors" :key="i" class="color-block"
               :style="{backgroundColor: item}">
            <div v-if="colorIndex==i" class="icon-selected"><i class="el-icon-check"></i></div>
          </div>
        </div>
        <div style="flex-grow: 1; text-align: right">
          <el-popover placement="top-end" width="300" trigger="click" v-if="editorMode==1">
            <p>绘制：鼠标左键点击及移动即可绘制图形</p>
            <p>结束绘制：鼠标左键双击即可结束绘制折线、多边形，多边形会自动闭合；圆形、椭圆单击即可结束</p>
            <p>中断：绘制过程中按下esc键可中断该过程</p>
            <el-button slot="reference">提示</el-button>
          </el-popover>
          <el-popover placement="top-end" width="300" trigger="click" v-if="editorMode==2">
            <p>单选：鼠标左键点击图形</p>
            <p>删除：选中图形后按下delete键或点击删除按钮可删除图形</p>
            <p>编辑：选中图形后出现编辑点，拖动编辑点可移动顶点位置，双击实心编辑点可删除顶点</p>
            <p>中断：按下esc键可中断当前操作，点选的图形将取消选中，编辑过程将中断 </p>
            <el-button slot="reference">提示</el-button>
          </el-popover>
        </div>
      </div>
      <div class="drawers">
        <div class="drawer" :class="{'active':drawIndex==0}" @click="selectDrawer(0)">
          <div class="icon">
            <img src="/images/map/draw-text.png">
          </div>
          <div>
            <div class="l1">文字标注</div>
            <div class="l2">不超过100字</div>
          </div>
        </div>
<!--        <div class="drawer" :class="{'active':drawIndex==1}" @click="selectDrawer(1)">-->
<!--          <div class="icon">-->
<!--            <img src="/images/map/draw-line.png">-->
<!--          </div>-->
<!--          <div>-->
<!--            <div class="l1">画线标注</div>-->
<!--            <div class="l2">绘制路线</div>-->
<!--          </div>-->
<!--        </div>-->
        <div class="drawer" :class="{'active':drawIndex==2}" @click="selectDrawer(2)">
          <div class="icon">
            <img src="/images/map/draw-polygon.png">
          </div>
          <div>
            <div class="l1">区域标注</div>
            <div class="l2">绘制多边形</div>
          </div>
        </div>
        <div class="drawer" :class="{'active':drawIndex==3}" @click="selectDrawer(3)">
          <div class="icon">
            <img src="/images/map/draw-circle.png">
          </div>
          <div>
            <div class="l1">圆型标注</div>
            <div class="l2">绘制圆型</div>
          </div>
        </div>
        <div class="drawer" :class="{'active':drawIndex==4}" @click="selectDrawer(4)">
          <div class="icon">
            <img src="/images/map/draw-image.png">
          </div>
          <div>
            <div class="l1">图片标注</div>
            <div class="l2">添加预设插图</div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="请选择插图" :visible.sync="dialogVisible" width="80%" top="5vh" :before-close="handleClose">
      <el-tabs v-model="activeName" type="card" @tab-click="handleClickImageTab">
        <el-tab-pane label="交通" name="traffic"></el-tab-pane>
        <el-tab-pane label="旅行" name="travel"></el-tab-pane>
        <el-tab-pane label="天气" name="weather"></el-tab-pane>
        <el-tab-pane label="动物" name="animal"></el-tab-pane>
        <el-tab-pane label="美食" name="food"></el-tab-pane>
        <el-tab-pane label="景点" name="resort"></el-tab-pane>
        <el-tab-pane label="越野" name="offroad"></el-tab-pane>
      </el-tabs>
      <div style="height: 60vh; overflow-y: auto;">
        <img v-for="(item,index) in editImages" :key="index" :src="item+80" @click="addImage(item)">
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible=false">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {getConfig, getColors, getLocusLabel} from '@/api'
import {getImage} from '@/api/image'
import PubSub from "pubsub-js";
import MapPoint from '@/components/mapPoint'

export default {
  name: "TencentMap",
  props: ['hideLabel'],
  data() {
    return {
      searchType: '1',
      searchText: '',
      searchLngLat: '',
      DEFAULT_CENTER: new window.TMap.LatLng(38, 104),
      DEFAULT_ZOOM: 5,
      MAX_ZOOM: 18,
      MIN_ZOOM: 5,
      map: null,
      zoom: 5,
      currentLayer: 3,
      showNetwork: false,
      showLayerBox: false,
      showFullscreen: false,
      yyTile: null,
      locusLayer: null,
      locusMarkLayer: null,
      markLayer: null,
      markShow: null,
      showDrawers: false,
      drawIndex: -1,
      colorIndex: 0,
      editor: null,
      editorMode: 1,
      editorColors: [],
      infoWindowMap: {},
      dialogVisible: false,
      editImages: [],
      editImage: null,
      imageStyles: {},
      activeName: 'traffic',
      suggestion: null,
      timer: null,
    }
  },
  async mounted() {
    this.editorColors = getColors()
    this.map = new window.TMap.Map(document.getElementById('map'), {
      minZoom: this.MIN_ZOOM,
      maxZoom: this.MAX_ZOOM,
      center: this.DEFAULT_CENTER,
      zoom: this.DEFAULT_ZOOM,
      viewMode: '2D',
      showControl: false,
      doubleClickZoom: false,
    });
    this.map.on('zoom', () => {
      let zoom = Math.floor(this.map.getZoom() * 100) / 100;
      PubSub.publish('zoom_changed', zoom)
      // this.resetLocusLabel(zoom)
    })
    this.map.on('center_changed', (e) => {
      let lat = Math.floor(e.center.lat * 1000000) / 1000000;
      let lng = Math.floor(e.center.lng * 1000000) / 1000000;
      PubSub.publish('center_changed', {lat, lng})
    })
    await getConfig(() => {
      // let url = data.locusTileUrl
      // url = url.replace('http:', 'https:')
      // const url1 = url.substr(0, url.indexOf('?') + 1)
      // const url2 = url.substring(url.lastIndexOf(','), url.length)
      // this.yyTile = new window.TMap.ImageTileLayer({
      //   getTileUrl: function (x, y, z) {
      //     var url = url1 + z + ',' + x + ',' + y + url2;
      //     return url;
      //   },
      //   tileSize: 256,
      //   visible: false,
      //   zIndex: 1,
      //   map: this.map,
      // })
    })
    let styles = {
      'locus': new window.TMap.PolylineStyle({
        color: '#e62',
        width: 5,
        lineCap: 'butt',
        showArrow: true,
        borderWidth: 1,
        borderColor: '#c30',
      }),
    }
    for (let i in this.editorColors) {
      styles['locus-' + i] = new window.TMap.PolylineStyle({
        color: this.colorToRGBA(this.editorColors[i], 0.9),
        width: 4,
        lineCap: 'butt',
        showArrow: true,
        borderWidth: 2,
        borderColor: this.editorColors[i],
      })
    }
    this.locusLayer = new window.TMap.MultiPolyline({
      id: 'locus-layer',
      map: this.map,
      styles: styles,
    })
    this.locusMarkLayer = new window.TMap.MultiMarker({
      map: this.map,
      styles: {
        'locusStart': new window.TMap.MarkerStyle({
          src: '/images/locus/start.png',
          width: 44,
          height: 44,
          anchor: {x: 22, y: 22}
        }),
        'locusEnd': new window.TMap.MarkerStyle({
          src: '/images/locus/end.png',
          width: 44,
          height: 44,
          anchor: {x: 22, y: 22}
        }),
      },
    })
    this.markLayer = new window.TMap.MultiMarker({
      map: this.map,
      styles: {
        'mark': new window.TMap.MarkerStyle({
          src: '/images/locus/mark.png',
          width: 44,
          height: 34,
        }),
        'mark-show': new window.TMap.MarkerStyle({
          src: '/images/locus/mark-show.png',
          width: 44,
          height: 34,
        }),
      },
    })
    this.markLayer.on('click', (e) => {
      if (this.markShow) {
        this.markShow.styleId = 'mark'
        this.markLayer.updateGeometries([this.markShow])
      }
      const g = e.geometry
      PubSub.publish('marker-click', g)
      g.styleId = 'mark-show'
      this.markLayer.updateGeometries([g])
      this.markShow = g
    })
    this.initEditor()
    this.suggestion = new window.TMap.service.Suggestion({
      pageSize: 20,
    })
    window.clickPoint = this.clickPoint

    // this.resetLocusLabel(this.zoom)
    this.editor.getOverlayList()[5].overlay.on('click', (evt) => {
      let lid = evt.geometry.properties.lid
      if (lid) {
        window.open('https://www.map6.net/locus-20220225-tuliu#/' + lid, '_blank');
      }
    })
  },
  methods: {
    colorToRGBA(color, alpha) {
      let r = 'rgba('
      let code = color.substr(1)
      for (let i = 0; i < 3; i++) {
        let s = code.substr(i * 2, 2)
        r += parseInt('0x' + s)
        r += ','
      }
      r += (alpha + ')')
      return r
    },
    initEditor() {
      let markerStyles = {}
      const transparentImage = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'
      for (let i in this.editorColors) {
        let color = this.editorColors[i]
        if (i == this.editorColors.length - 1) {
          // 处理黑色文字
          color = '#ffffff'
        }
        markerStyles['style-' + i] = new window.TMap.MarkerStyle({
          // strokeColor: this.colorToRGBA(this.editorColors[i], 0.8),
          // color: '#ffffff',
          strokeColor: '#000000',
          color: color,
          direction: 'bottom',
          src: transparentImage,
          strokeWidth: 2,
          size: 16,
          offset: {x: 0, y: -6},
        })
      }
      markerStyles.highlight = new window.TMap.MarkerStyle({
        src: '/images/map/edit-text.png',
        width: 140,
        height: 30,
        anchor: {x: 70, y: 15},
      })
      let polylineStyles = {}
      for (let i in this.editorColors) {
        polylineStyles['style-' + i] = new window.TMap.PolylineStyle({
          color: this.editorColors[i]
        })
      }
      let polygonStyles = {}
      for (let i in this.editorColors) {
        polygonStyles['style-' + i] = new window.TMap.PolygonStyle({
          borderColor: this.editorColors[i],
          showBorder: true,
          color: this.colorToRGBA(this.editorColors[i], 0.2),
        })
      }
      let circleStyles = {}
      for (let i in this.editorColors) {
        circleStyles['style-' + i] = new window.TMap.CircleStyle({
          borderColor: this.editorColors[i],
          showBorder: true,
          color: this.colorToRGBA(this.editorColors[i], 0.2),
        })
      }
      let labelStyles = {
        'star-0': new window.TMap.MarkerStyle({
          strokeColor: '#00b19e',
          color: '#fff',
          direction: 'bottom',
          src: transparentImage,
          strokeWidth: 2,
          size: 14,
        })
      }
      for (let i = 1; i <= 5; i++) {
        labelStyles['star-' + i] = new window.TMap.MarkerStyle({
          strokeColor: '#00b19e',
          color: '#fff',
          // direction: 'bottom',
          src: '/images/map/star-' + i + '.png',
          strokeWidth: 2,
          size: 14,
          width: 58,
          height: 14,
          offset: {x: 0, y: -12},
        })
      }
      this.editor = new window.TMap.tools.GeometryEditor({
        map: this.map,
        overlayList: [
          {
            overlay: new window.TMap.MultiMarker({
              map: this.map,
              styles: markerStyles,
            }),
            id: 'edit-marker',
            drawingStyleId: 'style-0',
            selectedStyleId: 'highlight',
          },
          {
            overlay: new window.TMap.MultiPolyline({
              map: this.map,
              styles: polylineStyles
            }),
            id: 'edit-polyline',
            drawingStyleId: 'style-0',
          },
          {
            overlay: new window.TMap.MultiPolygon({
              map: this.map,
              styles: polygonStyles
            }),
            id: 'edit-polygon',
            drawingStyleId: 'style-0',
          },
          {
            overlay: new window.TMap.MultiCircle({
              map: this.map,
              styles: circleStyles
            }),
            id: 'edit-circle',
            drawingStyleId: 'style-0',
          },
          {
            overlay: new window.TMap.MultiMarker({
              map: this.map,
              styles: this.imageStyles,
            }),
            id: 'edit-image',
          },
          {
            overlay: new window.TMap.MultiMarker({
              map: this.map,
              styles: labelStyles
            }),
            id: 'label-marker',
          },
        ],
        actionMode: window.TMap.tools.constants.EDITOR_ACTION.DRAW,
        activeOverlayId: 'edit-polyline',
        snappable: true,
        selectable: true,
      })
      this.editor.disable()
      this.editor.on('draw_complete', (geometry) => {
        geometry.type = this.editor.getActiveOverlay().id.substr(5)
        if (this.editor.getActiveOverlay().id == 'edit-marker') {
          this.$prompt('请输入标注的文本（不超过12字）', '提示', {
            closeOnClickModal: false,
            confirmButtonText: '添加',
            cancelButtonText: '取消',
            // inputPattern: /[0-9]{1,12}/,
            // inputErrorMessage: '标注文字不超过12字'
          }).then(({value}) => {
            // this.markerText = value
            if (value && value.trim()) {
              geometry.content = value
              this.editor.getOverlayList()[0].overlay.updateGeometries([geometry])
              PubSub.publish('geometry_add', geometry)
            }
          }).catch(() => {
            this.editor.getOverlayList()[0].overlay.remove([geometry.id])
          })
        } else if (this.editor.getActiveOverlay().id == 'edit-image') {
          this.dialogVisible = true
          if (this.editImages.length == 0) {
            this.editImages = getImage('traffic')
          }
          this.editImage = geometry
        } else {
          PubSub.publish('geometry_add', geometry)
        }
      })
      this.editor.on('delete_complete', (geometry) => {
        PubSub.publish('geometry_delete', geometry)
      })
      this.editor.on('adjust_complete', (geometry) => {
        PubSub.publish('geometry_update', geometry)
      })
    },
    getMap() {
      return this.map
    },
    network() {
      this.showNetwork = !this.showNetwork
      if (this.showNetwork) {
        this.yyTile.setVisible(false)
      } else {
        this.yyTile.setVisible(false)
      }
    },
    zoomIn() {
      this.zoom = this.map.getZoom()
      this.zoom++
      this.map.setZoom(this.zoom)
    },
    zoomOut() {
      this.zoom = this.map.getZoom()
      this.zoom--
      this.map.setZoom(this.zoom)
    },
    selectLayer(layer) {
      this.currentLayer = layer
      if (layer == 0) {
        this.map.setMapStyleId('style2')
        this.map.setBaseMap({
          type: 'vector'
        })
      } else if (layer == 1) {
        this.map.setBaseMap({
          type: 'satellite'
        })
      } else if (layer == 2) {
        console.log('not implemented')
      } else if (layer == 3) {
        this.map.setMapStyleId('style1')
        this.map.setBaseMap({
          type: 'vector'
        })
      }
      this.showLayerBox = false
    },
    stopClick(e) {
      e.stopPropagation()
    },
    getLocusList() {
      return this.locusLayer.getGeometries()
    },
    getPointList() {
      return this.markLayer.getGeometries()
    },
    removeLocus(ids) {
      this.locusLayer.remove(ids)
    },
    showLocus(data, lid = null, fit = true, colorIndex = 0) {
      let compressRatio = 0
      let minLat = 0, minLng = 0, maxLat = 0, maxLng = 0
      const nodes = data.split(';')
      const path = []
      let start = null
      let end = null
      if (data.length > 800000) {
        compressRatio = 32
      } else if (data.length > 400000) {
        compressRatio = 16
      } else if (data.length > 200000) {
        compressRatio = 8
      } else if (data.length > 100000) {
        compressRatio = 4
      }
      for (let i in nodes) {
        const n = nodes[i].split(',')
        if (i == 0) {
          start = new window.TMap.LatLng(n[1], n[0])
        } else if (i == nodes.length - 1) {
          end = new window.TMap.LatLng(n[1], n[0])
        }
        if (n.length == 2) {
          let lat = parseFloat(n[1])
          let lng = parseFloat(n[0])
          if (compressRatio == 0 || i % compressRatio == 0) {
            path.push(new window.TMap.LatLng(lat, lng))
          }
          if (minLat == 0) {
            minLat = lat
          } else {
            minLat = Math.min(minLat, lat)
          }
          if (minLng == 0) {
            minLng = lng
          } else {
            minLng = Math.min(minLng, lng)
          }
          if (maxLat == 0) {
            maxLat = lat
          } else {
            maxLat = Math.max(maxLat, lat)
          }
          if (maxLng == 0) {
            maxLng = lng
          } else {
            maxLng = Math.max(maxLng, lng)
          }
        }
      }
      if (!lid) {
        this.locusLayer.remove(['locus'])
        this.locusLayer.add([{
          'id': 'locus',
          'styleId': 'locus',
          'paths': path
        }])
        this.locusMarkLayer.setGeometries([])
        this.locusMarkLayer.add([{
          id: 'locusStart',
          styleId: 'locusStart',
          position: start
        }, {
          id: 'locusEnd',
          styleId: 'locusEnd',
          position: end
        }])
      } else {
        this.locusLayer.add([{
          'id': 'locus-' + lid,
          'styleId': 'locus-' + colorIndex,
          'paths': path
        }])
      }
      if (fit) {
        let bounds = new window.TMap.LatLngBounds(new window.TMap.LatLng(minLat, minLng), new window.TMap.LatLng(maxLat, maxLng))
        this.map.fitBounds(bounds, {padding: 100, minZoom: this.MIN_ZOOM, maxZoom: this.MAX_ZOOM})
      }
    },
    Point() {
      if (!this.markLayer == null) {
        this.markLayer.setGeometries([])
      }
    },
    showMarkers(data) {
      if (data && data.length > 0) {
        const p = []
        for (let i in data) {
          const x = data[i]
          p.push({
            id: 'point-' + i,
            styleId: 'mark',
            position: new window.TMap.LatLng(x.lat, x.lng)
          })
        }
        this.markLayer.add(p)
      }
    },
    addMarkers(data) {
      if (data && data.length > 0) {
        const p = []
        for (let i in data) {
          const x = data[i]
          p.push({
            id: 'point-' + x.pid,
            styleId: 'mark',
            position: new window.TMap.LatLng(x.lat, x.lng)
          })
        }
        this.markLayer.add(p)
      }
    },
    focusMark(data) {
      if (this.markShow) {
        this.markShow.styleId = 'mark'
        this.markLayer.updateGeometries([this.markShow])
      }
      const p = new window.TMap.LatLng(data.lat, data.lng)
      this.markShow = {
        id: data.id,
        styleId: 'mark-show',
        position: p
      }
      this.markLayer.updateGeometries([this.markShow])
      this.map.panTo(p)
    },
    addMark(lat, lng) {
      this.clean()
      this.editor.disable()
      const p = new window.TMap.LatLng(lat, lng)
      this.markLayer.add({
        id: 'mark-0',
        styleId: 'mark',
        position: p
      })
      this.map.setZoom(13)
      this.map.panTo(p)
    },
    clean() {
      if (this.editor) {
        this.editor.disable()
      }
      if (this.markLayer) {
        this.markLayer.setGeometries([])
      }
      if (this.locusLayer) {
        this.locusLayer.setGeometries([])
      }
      if (this.locusMarkLayer) {
        this.locusMarkLayer.setGeometries([])
      }
      if (this.editor) {
        const list = this.editor.getOverlayList()
        for (let i in list) {
          const overlay = list[i]
          overlay.overlay.setGeometries([])
        }
      }
      for (let i in this.infoWindowMap) {
        let info = this.infoWindowMap[i]
        info.destroy()
      }
      if (this.pointLayer) {
        this.pointLayer.clean()
      }
    },
    selectModeListener(e) {
      const p = e.latLng
      PubSub.publish('location-select', {lat: p.lat, lng: p.lng})
    },
    selectModeOn() {
      this.map.on('click', this.selectModeListener)
    },
    selectModeOff() {
      this.map.off('click', this.selectModeListener)
    },
    updateLocusStyle(id, styleId) {
      let locus = this.locusLayer.getGeometryById(id)
      locus.styleId = styleId
      this.locusLayer.updateGeometries([locus])
    },
    openDrawers() {
      this.showDrawers = true
    },
    closeDrawers() {
      this.showDrawers = false
    },
    selectDrawer(i) {
      this.editor.enable()
      this.drawIndex = i
      let layerId = ''
      if (i == 0) {
        layerId = 'edit-marker'
      } else if (i == 1) {
        layerId = 'edit-polyline'
      } else if (i == 2) {
        layerId = 'edit-polygon'
      } else if (i == 3) {
        layerId = 'edit-circle'
      } else if (i == 4) {
        layerId = 'edit-image'
      }
      this.editor.setActiveOverlay(layerId)
    },
    selectColor(index) {
      this.colorIndex = index
      const list = this.editor.getOverlayList()
      for (let i in list) {
        const overlay = list[i]
        overlay.drawingStyleId = 'style-' + index
      }
      // 腾讯地图的bug，需要切换下activeOverlay，再切换回原来的，才生效
      const id = this.editor.getActiveOverlay().id
      let otherId = 'edit-marker'
      if (id == 'edit-marker') {
        otherId = 'edit-circle'
      }
      this.editor.setActiveOverlay(otherId)
      this.editor.setActiveOverlay(id)
    },
    changeEditMode() {
      if (this.editorMode == 1) {
        this.editorMode = 2
        this.editor.setActionMode(window.TMap.tools.constants.EDITOR_ACTION.INTERACT)
      } else {
        this.editorMode = 1
        this.editor.setActionMode(window.TMap.tools.constants.EDITOR_ACTION.DRAW)
      }
    },
    setDefault() {
      this.map.setZoom(this.DEFAULT_ZOOM)
      this.map.setCenter(this.DEFAULT_CENTER)
    },
    setZoomAndCenter(zoom, lng, lat) {
      this.map.setZoom(zoom)
      this.map.setCenter({lng, lat})
    },
    getDefaultCenter() {
      return this.DEFAULT_CENTER
    },
    getDefaultZoom() {
      return this.DEFAULT_ZOOM
    },
    setGeometries(geometries) {
      const overlays = this.editor.getOverlayList()
      for (let i in geometries) {
        const g = geometries[i]
        g.id = 'handmap-' + i
        if (g.type == 'marker') {
          g.position = new window.TMap.LatLng(g.position.lat, g.position.lng)
          overlays[0].overlay.add([g])
        } else if (g.type == 'polyline') {
          const paths = g.paths
          for (let j in paths) {
            const p = paths[j]
            paths[j] = new window.TMap.LatLng(p.lat, p.lng)
          }
          overlays[1].overlay.add([g])
        } else if (g.type == 'polygon') {
          const paths = g.paths
          for (let j in paths) {
            const p = paths[j]
            paths[j] = new window.TMap.LatLng(p.lat, p.lng)
          }
          overlays[2].overlay.add([g])
        } else if (g.type == 'circle') {
          g.center = new window.TMap.LatLng(g.position.lat, g.position.lng)
          overlays[3].overlay.add([g])
        } else if (g.type == 'image') {
          g.position = new window.TMap.LatLng(g.position.lat, g.position.lng)
          let style = this.imageStyles[g.styleId]
          if (!style) {
            this.imageStyles[g.styleId] = new window.TMap.MarkerStyle({
              src: 'https://img.map6.net/map-img/' + g.styleId.substr(6) + '.png?imageView2/1/w/' + 50,
              width: 50,
              height: 50,
            })
          }
          overlays[4].overlay.add([g])
        }
      }
    },
    addPoint(point) {
      const color = this.editorColors[point.colorIndex]
      const rgba = this.colorToRGBA(color, 0.7)
      let content = '<div class="map-point-div" onclick="clickPoint(' + point.pid + ')">' +
          '<div class="map-point" style="background-color: ' + rgba + '"><img class="map-point-img" src="' + point.img + '">'
      if (point.des) {
        let des = point.des
        if (point.des.length > 6) {
          des = point.des.substr(0, 6) + '...'
        }
        content += ('<div class="map-point-text">' + des + '</div>')
      }
      content += '</div></div>'
      let info = new window.TMap.InfoWindow({
        map: this.map,
        enableCustom: true,
        position: new window.TMap.LatLng(point.lat, point.lng),
        content: content
      })
      this.infoWindowMap[point.pid] = info
    },
    addPoints(points) {
      for (let i in points) {
        let p = points[i]
        let colorIndex = parseInt(p.styleId.substring(6))
        p.color = this.editorColors[colorIndex]
      }
      this.pointLayer = new MapPoint({map: this.map, data: points})
      this.pointLayer.on('click', (e) => {
        this.clickPoint(e)
      })
    },
    removePoint(pid) {
      let info = this.infoWindowMap[pid]
      if (info) {
        info.destroy()
      }
    },
    handleClickImageTab(tab) {
      this.editImages = getImage(tab.name)
    },
    addImage(item) {
      let i = item.indexOf('img/')
      let j = item.indexOf('.png')
      let styleId = 'style-' + item.substring(i + 4, j)
      this.imageStyles[styleId] = new window.TMap.MarkerStyle({
        src: item + 50,
        width: 50,
        height: 50,
      })
      this.editImage.styleId = styleId
      this.editor.getOverlayList()[4].overlay.updateGeometries([this.editImage])
      this.dialogVisible = false
      PubSub.publish('geometry_add', this.editImage)
    },
    handleClose() {
      this.editor.getOverlayList()[4].overlay.remove([this.editImage.id])
      this.dialogVisible = false
    },
    queryAddr(queryString, cb) {
      if (queryString) {
        this.suggestion.getSuggestions({keyword: queryString}).then((data) => {
          let r = []
          if (data.data) {
            for (let i in data.data) {
              const rb = data.data[i]
              let value = rb.title
              if (rb.city) {
                value += ' ' + rb.city
              }
              r.push({value, address: rb.address, lat: rb.location.lat, lng: rb.location.lng})
            }
          }
          cb(r)
        })
      } else {
        cb([])
      }
    },
    handleAddrSelect(e) {
      this.addMark(e.lat, e.lng)
    },
    handleSearchLngLat() {
      let valid = false
      let lnglat = this.searchLngLat.split(',')
      if (lnglat.length == 2) {
        let lng = parseFloat(lnglat[0])
        let lat = parseFloat(lnglat[1])
        if (!isNaN(lng) && !isNaN(lat)) {
          if (lng <= 135.05 && lng >= 73.66 && lat <= 53.55 && lat >= 3.86) {
            valid = true
            this.addMark(lat, lng)
          }
        }
      }
      if (!valid) {
        this.$message.error('请输入有效经纬度，例：121.270084,31.752807');
      }
    },
    clickPoint(pid) {
      PubSub.publish('map-click-point', pid)
    },
    resetLocusLabel(zoom) {
      if(this.hideLabel) {
        return
      }
      zoom = Math.floor(zoom)
      if (zoom > 18) {
        zoom = 18
      } else if (zoom < 3) {
        zoom = 3
      }
      const overlays = this.editor.getOverlayList()
      let labels = []
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        getLocusLabel(zoom, (data) => {
          console.log(zoom + ':' + data.length)
          for (let j in data) {
            if (data[j].lat > -90 && data[j].lat < 90) {
              labels.push({
                position: new window.TMap.LatLng(data[j].lat, data[j].lng),
                content: data[j].name,
                styleId: 'star-' + data[j].level,
                properties: {
                  lid: data[j].lid
                }
              })
            }
          }
          overlays[5].overlay.setGeometries(labels)
        })
      }, 1000)
    },
  },
}
</script>

<style scoped>
.map-container {
  height: 100%;
  width: 100%;
  position: relative;
}

#map {
  height: 100%;
  width: 100%;
}

.map-fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 100;
}

#map {
}

#map-tools {
  z-index: 1100;
  position: absolute;
  right: 5px;
  top: 15px;
  user-select: none;
}

#map-drawers {
  z-index: 1100;
  position: absolute;
  right: 15px;
  bottom: 15px;
  user-select: none;
}

.box {
  margin-bottom: 5px;
  position: relative;
  cursor: pointer;
}

.box img {
  width: 35px;
}

.window {
  position: absolute;
  right: 45px;
  top: 0;
}

.window-layer {
  width: 330px;
  background-color: #000;
  opacity: 0.9;
  padding-left: 10px;
  padding-top: 10px;
}

.box-layer {
  display: inline-block;
  width: 100px;
  height: 66px;
  margin-right: 10px;
  margin-bottom: 10px;
  position: relative;
  cursor: pointer;
}

.box-layer img {
  width: 100px;
}

.box-layer span {
  display: inline-block;
  color: #fff;
  margin-top: -20px;
  width: 100px;
  text-align: center;
  position: absolute;
  bottom: 3px;
  left: 0;
}

.drawers {
  display: flex;
  background: #000;
  color: #777;
  letter-spacing: 1px;
  padding: 10px 0 10px 30px;
}

.drawers .active {
  color: #fb0;
}

.drawer {
  display: flex;
  width: 120px;
  align-items: center;
  cursor: pointer;
}

.drawer .icon {
  width: 30px;
  height: 30px;
  margin-right: 6px;
  overflow: hidden;
  position: relative;
}

.drawer img {
  width: 30px;
  height: 30px;
  filter: drop-shadow(30px 0 0 #fb0);
  position: absolute;
  top: 0;
}

.drawers .active img {
  left: -30px;
}

.drawer .l1 {
  font-size: 12px;
}

.drawer .l2 {
  font-size: 12px;
  transform: scale(0.8) translate(-10%, 0);
}

.colors {
  background: rgba(255, 255, 255, 0.7);
  height: 30px;
  padding: 6px 10px;
  display: flex;
  align-items: center;
  position: relative;
}

.color-block {
  width: 25px;
  height: 25px;
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
}

.btn-tool {
  display: flex;
  font-size: 14px;
  height: 30px;
  border-radius: 17px;
  padding: 0 10px;
  margin-right: 30px;
  cursor: pointer;
  width: 130px;
  white-space: nowrap;
}

.btn-tool-add {
  border: 2px solid #00B19E;
  color: #00B19E;
  background: #ffffff;
}

.btn-tool-edit {
  border: 2px solid #EC706F;
  color: #ffffff;
  background: #EC706F;
}

.btn-tool i {
  margin-right: 4px;
  font-size: 120%;
}

.btn-tool .des {
  font-size: 12px;
  transform: scale(0.8) translate(-12%, -20%);
}

.icon-selected {
  position: absolute;
  color: white;
  font-size: 120%;
  width: 25px;
  height: 25px;
  text-align: center;
  top: 0;
}

.el-popover__reference-wrapper button {
  padding: 6px 12px;
}
</style>

<style>
.map-point-div {

}

.map-point {
  display: flex;
  border-radius: 12px;
  margin-left: -20px;
  transform: translate(50%, 50%);
}

.map-point-img {
  width: 20px;
  height: 20px;
  border: 2px solid #ffffff;
  border-radius: 12px;
}

.map-point-text {
  letter-spacing: 0;
  display: inline-block;
  height: 24px;
  line-height: 24px;
  font-size: 14px;
  color: #ffffff;
  padding: 0 10px 0 4px;
}
</style>