<template>
  <div class="root">
    <Navigation :active="active" :menu="showMenu"/>
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"/>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" @mine="mine"/>
  </div>
</template>

<script>
import Navigation from "../../components/Navigation";

export default {
  name: "App",
  components: {Navigation},
  data() {
    return {
      active: 3,
      showMenu: false,
    }
  },
  mounted() {
  },
  methods: {
    mine() {
      this.active = 4
      this.showMenu = true
    }
  }
}
</script>

<style scoped>

</style>