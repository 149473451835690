<template>
  <div style="height: 70px; background-color: #000; position: fixed; width: 100%; top: 0" :class="{'normal':!showSubMenu,'front':showSubMenu}">
    <a href="pc.html">
      <img class="logo" src="/images/logo_S.png" alt="手抓地图官网">
    </a>
    <ul class="menu">
      <li>
        <a href="/index.html" :class="{'active': active == 0}">首页</a>
      </li>
<!--      <li>-->
<!--        <a href="/locus" :class="{'active': active == 1}">地图</a>-->
<!--      </li>-->
<!--      <li>-->
<!--        <a href="/note" :class="{'active': active == 2}">勘路笔记</a>-->
<!--      </li>-->
<!--      <li>-->
<!--        <a href="/user" :class="{'active': active == 3}">勘路者</a>-->
<!--      </li>-->
      <li>
        <a @click="toMine" :class="{'active': active == 4}">我的</a>
      </li>
      <li v-if="menu">
        <div class="edit" @mouseenter="enter">
          <img src="/images/mine/edit-0.png">
        </div>
      </li>
    </ul>
    <div class="sub-menu" @mouseleave="leave" v-show="showSubMenu">
      <div class="btn" @click="addPoint">
        <img height="20px" src="/images/mine/edit-1.png">
        <span>添加打点</span>
      </div>
      <div class="btn" @click="addMap">
        <img height="20px" src="/images/mine/edit-3.png">
        <span>添加特色地图</span>
      </div>
      <div class="btn" @click="logout">
        <span>退出</span>
      </div>
    </div>
  </div>
</template>

<script>
import {logout} from "@/api"
import PubSub from "pubsub-js";

export default {
  name: "Navigation",
  props: ['active', 'menu'],
  data() {
    return {
      showSubMenu: false
    }
  },
  mounted() {

  },
  methods: {
    enter() {
      this.showSubMenu = true
    },
    leave() {
      this.showSubMenu = false
    },
    addPoint() {
      PubSub.publish('menu-addPoint')
    },
    addMap() {
      PubSub.publish('menu-addMap')
    },
    logout() {
      logout(()=>{
        window.location.href = this.getBaseLink()
      })
    },
    toMine() {
      const link = window.location.href
      window.location.href = '/user#/mine'
      if(link.indexOf('/user') > 0) {
        location.reload()
      }
    },
    getBaseLink() {
      let link = window.location.href
      return link.substring(0,  link.indexOf("/", 10))
    }
  }
}
</script>

<style scoped>
.logo {
  padding-top: 15px;
  padding-left: 25px;
}

.menu {
  top: 23px;
  right: 13px;
  position: absolute;
  padding: 0;
  margin: 0;
  letter-spacing: 2px;
}

li {
  border-right: 1px solid #333;
  color: #999;
  padding-left: 9px;
  padding-right: 9px;
  display: inline-block;
  height: 22px;
  font-size: 16px;
  list-style: none;
}

li:hover {
  color: #bbb;
}

.menu a {
  text-decoration: none;
  color: #999;
}

.menu a.active {
  color: #fb0;
}

.edit {
  background-color: #fb0;
  border: 4px solid #640;
  border-radius: 50%;
  width: 44px;
  height: 44px;
  text-align: center;
  margin-top: -10px;
  position: relative;
}

.edit img {
  position: absolute;
  top: 10px;
  left: 10px;
}

.sub-menu {
  background-color: #000;
  border-radius: 0px 0px 8px 8px;
  width: 160px;
  height: 120px;
  position: absolute;
  top: 70px;
  right: 0px;
  padding-top: 10px;
}

.btn {
  background-color: #0ba;
  width: 140px;
  height: 24px;
  border-radius: 12px;
  font-size: 14px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  cursor: pointer;
  margin-bottom: 10px;
}

.btn span {
  display: inline-block;
  margin-left: 4px;
}

.normal {
  z-index: 10;
}

.front {
  z-index: 2000;
}

.menu a {
  cursor: pointer;
}

</style>