<template>
  <div class="preview" :class="{'map-active': active}">
    <div class="img">
      <img :src="data.coverUrl">
    </div>
    <div class="text">
      <div class="title">{{ data.title }}</div>
      <div class="sub-title">{{ data.subTitle }}</div>
      <div class="status">{{ data.status|status }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MapList",
  props: ['data', 'active', 'index'],
  filters: {
    status(value) {
      switch (value) {
        case 0:
          return ''
        case 9:
          return '已驳回'
        case 10:
          return '待审核'
        case 11:
          return '已审核'
        case 20:
          return '已上架'
        default:
          return ''
      }
    }
  }
}
</script>

<style scoped>
.preview {
  display: flex;
  height: 80px;
  width: 100%;
  background-color: #555;
  cursor: pointer;
}

.map-active {
  background-color: #4CA08D;
}

.preview .img {
  width: 80px;
  height: 80px;
}

.preview .img > img {
  width: 80px;
  height: 80px;
}

.preview .text {
  padding: 6px 8px 6px 8px;
  color: #fff;
  font-size: 12px;
  position: relative;
  width: calc(100% - 80px);
}

.preview .text .title {
  font-size: 16px;
}

.preview .text .sub-title {
  white-space: pre;
}

.preview .text .status {
  position: absolute;
  bottom: 6px;
  right: 8px;
}

.preview .text .creator {
  position: absolute;
  bottom: 10px;
  left: 10px;
}

.preview .text .detail {
  position: absolute;
  bottom: 10px;
  right: 10px;
}
</style>