import axios from "axios";
import CryptoJS from 'crypto-js'
import qs from 'qs'

// export const BASE = 'http://www.map6.net/'
export const BASE = '/'

export const toLogin = () => {
    // let link = window.location.href
    // link = link.substring(link.indexOf("/", 10))
    window.location.href = 'http://www.map6.net/wpapi2/login/loginByWx?devicePlatform=4&successUrl=http://www.map6.net'
}

export const req = (url, callback, data = {}, type = 'GET', raw = false) => {
    type = type.toUpperCase()
    if (type === 'GET') {
        let dataStr = ''
        Object.keys(data).forEach(key => {
            if (data[key] != null) {
                dataStr += key + '=' + data[key] + '&'
            }
        })
        dataStr += 'platform=4&sign=AAA&'
        if (dataStr !== '') {
            dataStr = dataStr.substring(0, dataStr.lastIndexOf('&'))
            url = url + '?' + dataStr
        }
        return axios.get(BASE + url).then(function (ret) {
            if (!raw) {
                const r = JSON.parse(textDecrypt(ret.data))
                if (r.status == 200) {
                    callback(r.data)
                } else {
                    console.log('err', r)
                    if (r.status == 402) {
                        toLogin()
                    }
                }
            } else {
                const r = textDecrypt(ret.data)
                callback(r)
            }
        }).catch((err) => {
            console.log('network err', err)
        })
    } else if (type === 'POST') {
        data.platform = 4
        data.sign = 'AAA'
        return axios.post(BASE + url, qs.stringify(data)).then(function (ret) {
            const r = JSON.parse(textDecrypt(ret.data))
            if (r.status == 200) {
                callback(r.data)
            } else {
                console.log('err', r)
                if (r.status == 402) {
                    toLogin()
                }
            }
        }).catch((err) => {
            console.log('network err', err)
        })
    } else if (type === 'POST-FORM') {
        let param = new FormData();
        data.platform = 4
        data.sign = 'AAA'
        for (let i in data) {
            param.append(i, data[i])
        }
        let config = {
            headers: {'Content-Type': 'multipart/form-data'}
        };
        return axios.post(BASE + url, param, config).then((ret) => {
            const r = JSON.parse(textDecrypt(ret.data))
            if (r.status == 200) {
                callback(r.data)
            } else {
                console.log('err', r)
                if (r.status == 402) {
                    toLogin()
                }
            }
        }).catch((err) => {
            console.log('network err', err)
        })
    }
}

const aK6 = "\x61\x70\x69\x32\x31\x34\x32\x31\x46\x6A\x4A\x75\x4A\x79\x58\x68"
const zYz4 = "\x61\x70\x69\x32\x31\x34\x4F\x6A\x4F\x6C\x55\x53\x76\x43\x39\x78";
const CBCOptions = {
    iv: CryptoJS.enc.Utf8.parse(zYz4),
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
}
const key = CryptoJS.enc.Utf8.parse(aK6);

function textDecrypt(txt) {
    const str = decodeURI(txt).replace(/\s/g, "\n").replace(/\n/g, "");
    const dec = CryptoJS.AES.decrypt(
        str, key, CBCOptions
    );
    const dt = CryptoJS.enc.Utf8.stringify(dec).toString();
    return dt;
}