<template>
  <div class="info">
    <div class="panel panel-form">
      <div style="width: 100%">
        <div>
          <img :src="user.fpUrl" width="100%">
        </div>
      </div>
      <div class="user-info">
        <img :src="user.uhead+'?imageView2/1/w/84'">
        <div class="name">{{ user.uname }}</div>
        <div class="dis">{{ (user.disNum / 1000).toFixed(2) }}</div>
        <div class="dis-text">勘路总里程/km</div>
      </div>
      <el-tabs v-model="tab">
        <el-tab-pane name="1">
          <span slot="label">路线 ({{ locusNum }})</span>
          <LocusList class="locus-list" v-for="(item, i) in user.content" :key="'locus-' + i"
                     :data="item" :index="i" :active="locusIndex == i" :show-bg="true"/>
          <div class="more" @click="addMore">点击加载更多</div>
        </el-tab-pane>
        <el-tab-pane name="2">
          <span slot="label">打点 ({{ pointNum }})</span>
          <div class="point-container" style="display: flex; flex-wrap: wrap; justify-content: space-between">
            <div class="point-list" v-for="(item,i) in points" :key="i"
                 @click="showPoint(item.pid, i)">
              <img :src="item.img" style="object-fit: cover;" :class="[i==pointIndex?'active':'inactive']">
              <div class="point-des">{{ item.des }}</div>
            </div>
            <div class="more-point" @click="addMorePoint">
              <div>加载更多</div>
            </div>
            <i></i><i></i><i></i>
          </div>
        </el-tab-pane>
        <el-tab-pane name="3" v-if="isMine">
          <span slot="label">地图 ({{ map.total }})</span>
          <div class="map-list" v-for="(item,i) in map.list" :key="i" @click="showMap(item.id, i)">
            <MapList :data="item" :active="map.index == i"/>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="sub-panel" v-show="showSubPanel" ref="subPanel">
      <LocusInfo v-if="mode=='locus'" :data="locus" :comment="comment" ref="locus"/>
      <PointInfo v-if="mode=='point'" :data="point" index="-1" show-control="1" :enable-edit="pointEdit"/>
      <PointForm v-if="mode=='edit-point'"/>
      <MapForm v-if="mode=='edit-map'" :default-center="defaultCenter" :default-zoom="defaultZoom" :data="map.info"/>
    </div>
    <div id="map-wrapper">
      <TencentMap ref="map"/>
    </div>
    <div class="open" v-show="showOpen" @click="openLocus">
      <img src="/images/locus/open.png" width="26px">
    </div>
    <div v-if="!isMine" class="back" @click="close">
      <i class="el-icon-back"></i>
    </div>
  </div>
</template>

<script>
import {
  getLocusList,
  getLocusInfo,
  getComments,
  getWaypoints,
  getPoints,
  getPointInfo,
  ensureUserLogin,
  syncLoginStatus,
  getMyMaps,
  getMapInfo,
} from "@/api"
import TencentMap from "../../components/map/TencentMap";
import LocusList from "../../components/LocusList";
import LocusInfo from "../../components/LocusInfo";
import PubSub from "pubsub-js";
import PointInfo from "../../components/PointInfo";
import PointForm from "../../components/form/PointForm";
import MapForm from "../../components/form/MapForm";
import MapList from "../../components/MapList";

export default {
  name: "UserInfo",
  components: {MapList, MapForm, PointInfo, LocusInfo, LocusList, TencentMap, PointForm},
  data() {
    return {
      offset: 0,
      limit: 30,
      uid: null,
      user: {},
      showSubPanel: false,
      locusIndex: -1,
      locus: {},
      showOpen: false,
      comment: [],
      pOffset: 0,
      pLimit: 100,
      locusNum: 0,
      pointNum: 0,
      points: [],
      point: {},
      pointIndex: -1,
      mode: 'locus',
      isMine: false,
      pointEdit: false,
      tab: '1',
      defaultCenter: null,
      defaultZoom: null,
      map: {
        index: -1,
        limit: 20,
        offset: 0,
        list: [],
        total: 0,
        info: null,
      },
    }
  },
  async mounted() {
    this.uid = this.$route.params.uid
    if (this.$route.query.tab) {
      this.tab = this.$route.query.tab
    }
    if (this.uid == 'mine') {
      // 我的
      this.$emit('mine')
      this.isMine = true
      ensureUserLogin((data) => {
        this.uid = data.uid
        this.init()
        this.pointEdit = true
      })
      PubSub.subscribe('menu-addPoint', () => {
        this.showSubPanel = true
        this.mode = 'edit-point'
        this.cleanAllMode()
        this.$refs.map.setDefault()
        this.$refs.map.selectModeOn()
      })
      PubSub.subscribe('menu-addMap', () => {
        this.showSubPanel = true
        this.mode = 'edit-map'
        this.cleanAllMode()
        this.$refs.map.setDefault()
        this.$refs.map.openDrawers()
        this.map.info = null
      })
      PubSub.subscribe('add-point', (e, data) => {
        this.$refs.map.addMark(data.lat, data.lng)
      })
    } else {
      syncLoginStatus()
      await this.init()
    }
    PubSub.subscribe('show-locus', (e, data) => {
      this.showLocus(data.lid)
      this.locusIndex = data.index
    })
    PubSub.subscribe('close-locus', () => {
      this.closeLocus()
    })
    PubSub.subscribe('focus-point', (e, data) => {
      this.focusPoint(data.index)
    })
    PubSub.subscribe('marker-click', (e, marker) => {
      const id = marker.id
      if (id.startsWith('point-')) {
        const index = parseInt(id.substring(6))
        this.focusPoint(index)
        const top = document.getElementById(id).offsetTop - 70
        this.$refs.subPanel.scrollTo(0, top)
      }
    })
    PubSub.subscribe('edit-point-cancel', () => {
      this.showSubPanel = false
      this.$refs.map.clean()
    })
    PubSub.subscribe('edit-map-cancel', () => {
      this.showSubPanel = false
      this.$refs.map.clean()
      this.$refs.map.closeDrawers()
    })
    PubSub.subscribe('edit-point-success', (e, data) => {
      this.points.splice(0, 0, {pid: data.pid, img: data.img})
      this.pointIndex = 0
      this.showPoint(data.pid, 0)
      this.mode = 'point'
      // this.$refs.map.clean()
    })
    PubSub.subscribe('edit-map-start', () => {
      this.$refs.map.openDrawers()
    })
    PubSub.subscribe('edit-map-end', () => {
      this.$refs.map.closeDrawers()
    })
    PubSub.subscribe('add-map-success', (e, data) => {
      this.tab = '3'
      this.map.list.splice(0, 0, data)
    })
    PubSub.subscribe('delete-map', (e, data) => {
      this.tab = '3'
      for (let i in this.map.list) {
        const m = this.map.list[i]
        if (m.id == data) {
          this.map.list.splice(i, 1)
          break
        }
      }
      this.showSubPanel = false
      this.$refs.map.clean()
    })
    PubSub.subscribe('map-add-locus', (e, locus) => {
      getWaypoints(locus.lid, (data) => {
        this.$refs.map.showLocus(data, locus.lid, false, locus.colorIndex)
      })
    })
    PubSub.subscribe('map-add-point', (e, point) => {
      this.$refs.map.addPoint(point)
    })
    PubSub.subscribe('change-color-locus', (e, data) => {
      this.$refs.map.updateLocusStyle('locus-' + data.lid, 'locus-' + data.colorIndex)
    })
    PubSub.subscribe('change-color-point', (e, data) => {
      this.$refs.map.removePoint(data.pid)
      this.$refs.map.addPoint(data)
    })
    PubSub.subscribe('map-remove-locus', (e, data) => {
      this.$refs.map.removeLocus(['locus-' + data])
    })
    PubSub.subscribe('map-remove-point', (e, data) => {
      this.$refs.map.removePoint(data)
    })
    PubSub.subscribe('delete-point', (e, data) => {
      this.showSubPanel = false
      this.pointIndex = -1
      let i = -1
      for (i in this.points) {
        if (this.points[i].pid == data.pid) {
          break
        }
      }
      if (i >= 0) {
        this.points.splice(i, 1)
      }
    })
    PubSub.subscribe('update-point', (e, data) => {
      let i = -1
      for (i in this.points) {
        if (this.points[i].pid == data.pid) {
          this.points[i].img = data.img + '?imageView2/1/w/200'
          break
        }
      }
    })
    this.defaultCenter = this.$refs.map.getDefaultCenter()
    this.defaultZoom = this.$refs.map.getDefaultZoom()
  },
  methods: {
    listDifference(x, y, key) {
      let clone = x.slice(0);
      for (let i = 0; i < y.length; i++) {
        let temp = y[i];
        for (let j = 0; j < clone.length; j++) {
          if (temp[key] === clone[j][key]) {
            clone.splice(j, 1);
          }
        }
      }
      return clone;
    },
    async init() {
      await getPoints(this.uid, null, this.pOffset, this.pLimit, 1, null, (data) => {
        this.pointNum = data.pointCount
        this.points = data.points
      })
      await getLocusList(this.offset, this.limit, 1, this.uid, null, (data) => {
        this.user = data //包含了Locus List
        this.locusNum = data.locusNum
      })
      if (this.isMine) {
        await getMyMaps(this.map.offset, this.map.limit, (data) => {
          this.map.total = data.mapNum
          this.map.list = this.map.list.concat(data.content)
        })
      }
    },
    async addMore() {
      this.offset += this.limit
      await getLocusList(this.offset, this.limit, 1, this.uid, null, (data) => {
        this.user.content = this.user.content.concat(data.content)
      })
    },
    async addMorePoint() {
      this.pOffset += this.pLimit
      await getPoints(this.uid, null, this.pOffset, this.pLimit, 1, null, (data) => {
        this.points = this.points.concat(data.points)
      })
    },
    async showLocus(lid) {
      this.mode = 'locus'
      this.cleanAllMode()
      this.showOpen = false
      this.locus.content = []
      this.comment = []
      this.showSubPanel = true
      this.$refs.map.clean()
      await getLocusInfo(lid, (data) => {
        this.locus = data
        const markers = []
        if (this.locus.content && this.locus.content.length > 0) {
          for (let i in this.locus.content) {
            const p = this.locus.content[i]
            markers.push({lat: p.lat, lng: p.lng, pid: p.pid})
          }
        }
        this.$refs.map.showMarkers(markers)
      })
      await getComments(1, lid, 0, 10000, (data) => {
        this.comment = data.content
      })
      await getWaypoints(lid, (data) => {
        this.$refs.map.showLocus(data)
      })
    },
    closeLocus() {
      this.showSubPanel = false
      this.showOpen = true
    },
    openLocus() {
      this.showSubPanel = true
      this.showOpen = false
    },
    focusPoint(i) {
      if (i == -1) {
        return
      }
      const p = this.locus.content[i]
      this.$refs.locus.setPointIndex(i)
      this.$refs.map.focusMark({
        id: 'point-' + i,
        lat: p.lat,
        lng: p.lng
      })
    },
    close() {
      this.$router.push({name: 'user'})
    },
    async showPoint(pid, i) {
      this.cleanAllMode()
      this.pointIndex = i
      await getPointInfo(pid, 1, this.user.uid, (data) => {
        this.point = data
        this.showSubPanel = true
        this.mode = 'point'
        this.$refs.map.focusMark({
          id: 'point-0',
          lat: data.lat,
          lng: data.lng
        })
      })
    },
    cleanAllMode() {
      this.locusIndex = -1
      this.map.index = -1
      this.$refs.map.clean()
      this.$refs.map.selectModeOff()
      this.$refs.map.closeDrawers()
    },
    async showMap(id, i) {
      this.cleanAllMode()
      this.map.index = i
      await getMapInfo(id, (data) => {
        this.map.info = data
        this.showSubPanel = true
        this.mode = 'edit-map'
        this.$refs.map.setZoomAndCenter(data.zoom, data.lng, data.lat)
        this.$refs.map.setGeometries(data.geometryList)
        if (data.locusList) {
          for (let i in data.locusList) {
            const l = data.locusList[i]
            l.colorIndex = parseInt(l.styleId.substring(6))
            getWaypoints(l.lid, (data) => {
              this.$refs.map.showLocus(data, l.lid, false, l.colorIndex)
            })
          }
        }
        if (data.pointList) {
          for (let i in data.pointList) {
            const p = data.pointList[i]
            p.colorIndex = parseInt(p.styleId.substring(6))
            this.$refs.map.addPoint(p)
          }
          // this.$refs.map.addPoints(data.pointList)
        }
      })
    }
  }
}
</script>

<style scoped>
.info {
  display: flex;
  height: calc(100vh - 74px);
}

#map-wrapper {
  flex-grow: 1;
}

.panel {
  width: 340px;
  flex-grow: 0;
  overflow: auto;
  position: relative;
  scrollbar-width: none;
  color: #fff;
}

.panel::-webkit-scrollbar {
  display: none;
}

.locus-list {
  margin-bottom: 5px;
}

.user-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.user-info img {
  border-radius: 50%;
  width: 80px;
  border: 2px solid #fff;
}

.user-info .name {
  font-size: 16px;
  margin-bottom: 16px;
}

.user-info .dis {
  font-size: 28px;
  letter-spacing: 0px;
}

.user-info .dis-text {
  font-size: 12px;
  color: #777;
  margin-bottom: 20px;
}

.more {
  display: inline-block;
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #777;
  font-size: 16px;
  margin-bottom: 5px;
  cursor: pointer;
}

.sub-panel {
  width: 400px;
  flex-grow: 0;
  height: calc(100vh - 70px);
  overflow: auto;
  scrollbar-width: none;
  background-color: #000;
}

.sub-panel::-webkit-scrollbar {
  display: none;
}

.open {
  position: absolute;
  top: 200px;
  left: 340px;
  cursor: pointer;
  z-index: 3000;
}

.back {
  position: absolute;
  top: 80px;
  left: 10px;
  z-index: 3000;
  color: #eee;
  background: #666;
  font-size: 150%;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  text-align: center;
  cursor: pointer;
}

.point-list {
  width: 83px;
}

.more-point {
  display: inline-block;
  width: 83px;
  height: 83px;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #777;
  font-size: 16px;
  cursor: pointer;
}

.active {
  border: 2px solid #fb0;
  width: 79px;
  height: 79px;
}

.inactive {
  width: 83px;
  height: 83px;
}

.point-list img {
  cursor: pointer;
}

.point-container > i {
  width: calc(25% - 2px);
  height: 0;
}

.map-list {
  padding: 2px 8px 8px 8px;
}

.point-des {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 14px;
  line-height: 14px;
  margin-bottom: 10px;
  font-size: 12px;
  letter-spacing: 0;
  color: #999;
}
</style>