function MapPoint(options) {
    window.TMap.DOMOverlay.call(this, options);
}

MapPoint.prototype = new window.TMap.DOMOverlay();

MapPoint.prototype.onInit = function (options) {
    this.map = options.map
    this.data = options.data
    this.map.on('resize', () => {
        let mapContainer = document.getElementById('map')
        document.getElementById('svgDom').setAttribute('width', mapContainer.offsetWidth)
        document.getElementById('svgDom').setAttribute('height', mapContainer.offsetHeight)
    })
};

MapPoint.prototype.updateDOM = function () {
    let nodes = this.dom.getElementsByTagName('g')
    for (let i in this.data) {
        let p = this.data[i]
        let _pos = this.map.projectToContainer(new window.TMap.LatLng(p.lat, p.lng))
        let g = nodes[i]
        g.setAttribute('transform', 'translate(' + _pos.x + ', ' + _pos.y + ')')
    }
}

MapPoint.prototype.clean = function() {
    let svg = document.getElementById('svgDom');
    svg.remove()
}

MapPoint.prototype.createDOM = function () {
    let SVG_NS = 'http://www.w3.org/2000/svg'
    let svg = document.createElementNS(SVG_NS, 'svg')
    let mapContainer = document.getElementById('map');
    svg.id = 'svgDom';
    svg.setAttribute('width', mapContainer.offsetWidth);
    svg.setAttribute('height', mapContainer.offsetHeight);

    let defs = document.createElementNS(SVG_NS, 'defs')
    svg.appendChild(defs)
    let cp = document.createElementNS(SVG_NS, 'clipPath')
    defs.appendChild(cp)
    cp.setAttribute('id', 'imageClip')
    let c = document.createElementNS(SVG_NS, 'circle')
    cp.appendChild(c)
    c.setAttribute('cx', '12')
    c.setAttribute('cy', '12')
    c.setAttribute('r', '10')

    for (let i in this.data) {
        let p = this.data[i]
        let _pos = this.map.projectToContainer(new window.TMap.LatLng(p.lat, p.lng))
        let g = document.createElementNS(SVG_NS, 'g');
        g.setAttribute('id', 'test' + i)
        g.setAttribute('transform', 'translate(' + _pos.x + ', ' + _pos.y + ')')
        let path = document.createElementNS(SVG_NS, 'path');
        path.setAttribute('d', 'M12 0 L112 0 A12 12 0 1 1 112 24 L12 24 Z')
        path.setAttribute('fill', p.color)
        path.setAttribute('fill-opacity', '0.6')
        g.appendChild(path)

        let c = document.createElementNS(SVG_NS, 'circle');
        c.setAttribute('cx', '12')
        c.setAttribute('cy', '12')
        c.setAttribute('r', '12')
        c.setAttribute('fill', '#FFFFFF')
        g.appendChild(c)

        let image = document.createElementNS(SVG_NS, 'image');
        image.setAttribute('x', '2')
        image.setAttribute('y', '2')
        image.setAttribute('width', '20')
        image.setAttribute('height', '20')
        image.setAttribute('href', p.img)
        image.setAttribute('clip-path', 'url(#imageClip)')
        g.appendChild(image)

        svg.appendChild(g)

        if (p.des) {
            let des = p.des
            if (p.des.length > 6) {
                des = p.des.substr(0, 6) + '...'
            }
            let text = document.createElementNS(SVG_NS, 'text');
            text.setAttribute('x', 26)
            text.setAttribute('y', 17)
            text.setAttribute('fill', '#FFFFFF')
            text.setAttribute('font-size', '13')
            text.innerHTML = des
            g.appendChild(text)
        }

        g.addEventListener('click', () => {
            this.emit('click', p.pid)
        })
    }
    return svg
}

module.exports = MapPoint;