<template>
  <div class="icon">
    <i :class='"el-icon-" + this.icon'></i><span style="margin-left: 2px">{{ text }}</span>
  </div>
</template>

<script>
export default {
  name: "LittleIconText",
  props: ['icon', 'text'],
  methods: {
  }
}
</script>

<style scoped>
.icon {
  display: inline-block;
  color: #1896DE;
  font-size: 12px;
  cursor: pointer;
  margin-left: 12px;
}
</style>