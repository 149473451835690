<template>
  <div>
    <Search :callback="searchUser" category="勘路者"/>
    <div class="user-container">
      <div class="user" v-for="(item, i) in content" :key="i" @click="showUser(item.uid)">
        <img :src="item.head + '?imageView2/1/w/120'">
        <div class="text">
          <div class="name">{{ item.uname }}</div>
          <div v-if="item.disNum" class="dis">{{ (item.disNum / 1000).toFixed(0) }} km</div>
        </div>
      </div>
      <div class="more" @click="addMore">
        <div>加载更多</div>
      </div>
      <i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i>
    </div>
  </div>
</template>

<script>
import {getUsers} from "@/api"
import Search from "../../components/form/Search";

export default {
  name: "User",
  components: {Search},
  data() {
    return {
      offset: 0,
      limit: 150,
      content: [],
      search: '',
    }
  },
  async mounted() {
    await this.loadUser()
  },
  methods: {
    async addMore() {
      this.offset += this.limit
      await this.loadUser()
    },
    async loadUser() {
      await getUsers(this.search, this.offset, this.limit, (data) => {
        this.content = this.content.concat(data.content)
      })
    },
    showUser(uid) {
      this.$router.push({name: 'userInfo', params: {uid}})
    },
    searchUser(text) {
      this.content = []
      this.offset = 0
      this.search = text
      this.loadUser()
    }
  }
}
</script>

<style scoped>
.user-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.user {
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding: 3px;
}

.user img {
  width: 120px;
  height: 120px;
}

.user .text {
  position: absolute;
  left: 3px;
  bottom: 7px;
  background-color: #000;
  opacity: 0.5;
  width: 120px;
  height: 30px;
  color: white;
}

.user .name {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-left: 4px;
  font-size: 12px;
}

.user .dis {
  font-size: 12px;
  transform: scale(0.7);
  margin-left: -16px;
}

.user-container > i {
  width: 126px;
  height: 0;
}

.more {
  display: inline-block;
  width: 120px;
  height: 120px;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #777;
  font-size: 16px;
  cursor: pointer;
}
</style>