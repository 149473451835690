import Vue from 'vue'
import VueRouter from 'vue-router'
import User from "./User";
import UserInfo from "./UserInfo";

Vue.use(VueRouter)

export default new VueRouter({
    base: '/user',
    // mode: 'history',
    routes: [
        {
            path: '/',
            component: User,
            name: 'user',
            meta: {
                keepAlive: true,
            }
        },
        {
            path: '/:uid',
            component: UserInfo,
            name: 'userInfo'
        },
    ]
})