<template>
  <div class="locus">
    <div class="control">
      <div>
<!--        <img src="/images/locus/link.png" width="70px">-->
<!--        <img src="/images/locus/share.png" width="70px">-->
      </div>
      <div>
        <img src="/images/locus/close.png" width="20px" @click="close">
      </div>
    </div>
    <div class="title text">{{ data.title }}</div>
    <div class="compact text">{{ data.time|dateTime }}</div>
    <div class="compact text">{{ data.uname }} | 打点 {{ data.markNum }} |
      浏览 {{ data.readNum }} | 赞 {{ data.likeNum }} | 总里程 {{ (data.disNum / 1000).toFixed(2) }} km
    </div>
    <PointInfo class="point-list" v-for="(item, i) in data.content" :key="i" :id="'point-' + i" :data="item" :index="i"
               :active="i == pointIndex"/>
    <div
        style="display: flex; justify-content: center; align-items: center; width: 100%; height: 70px; font-size: 16px;">
      已经到底
    </div>
    <div style="display: flex; padding-bottom: 10px">
      <div style="flex-grow: 0; padding-left: 8px; font-size: 16px">对轨迹的评论：</div>
      <div style="flex-grow: 1; text-align: right; padding-right: 13px; margin-top: 6px">
        <img src="/images/locus/comment@2x.png" width="54px">
      </div>
    </div>
    <div class="text compact comment" style="padding-bottom: 30px">
      <div v-for="(comment, i) in comment" :key="'locus-comment-' + i">
        <span class="user">{{ comment.uname }}:</span>
        <span class="comment">{{ comment.content }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import PointInfo from "./PointInfo";
import PubSub from "pubsub-js";

export default {
  name: "LocusInfo",
  components: {PointInfo},
  props: ['data', 'comment'],
  data() {
    return {
      pointIndex: -1,
    }
  },
  mounted() {
    PubSub.subscribe('focus-point', (e, data) => {
      this.pointIndex = data.index
    })
  },
  methods: {
    close() {
      PubSub.publish('close-locus')
    },
    setPointIndex(i) {
      this.pointIndex = i
    }
  },
  watch: {
    data() {
      this.pointIndex = -1
    }
  }
}
</script>

<style scoped>
.locus {
  color: #777;
  font-size: 12px;
}

.control {
  display: flex;
  justify-content: space-between;
  padding: 0px 10px 5px 0px;
}

.control img {
  cursor: pointer;
}

.title {
  font-size: 24px;
}

.text {
  padding: 0 8px 10px 8px;
}

.compact {
  letter-spacing: 0;
}

.comment .user {
  color: #17b;
  margin-right: 10px;
}

.point-list {
  margin-bottom: 5px;
}
</style>