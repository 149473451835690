<template>
  <div class="btn-file">
    <img src="/images/mine/form-file.png" width="20px" height="14px">
    <div>从电脑上选择</div>
    <input type="file" class="file" ref="file" @change="getFile" :accept="accept">
  </div>
</template>

<script>
export default {
  name: "FileUploader",
  data() {
    return {}
  },
  props: ['accept', 'callback'],
  methods: {
    clear() {
      this.$refs.file.value = ''
    },
    open() {
      this.$refs.file.dispatchEvent(new MouseEvent('click'))
    },
    getFile() {
      const file = this.$refs.file.files[0]
      const index = this.accept.indexOf(file.type)
      if (index == -1) {
        this.$alert('上传格式错误', '提示');
      } else {
        if (file.type == 'image/jpeg' || file.type == 'image/png') {
          const img = new Image()
          const reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = (e) => {
            img.src = e.target.result
            if (file.size > 323000) {
              const ratio = Math.sqrt(file.size / 323000) * 0.8
              img.onload = () => {
                const _w = img.width
                const _h = img.height
                const w = Math.round(_w / ratio)
                const h = Math.round(_h / ratio)
                const canvas = document.createElement('canvas')
                let ctx = canvas.getContext('2d')
                canvas.setAttribute('width', w)
                canvas.setAttribute('height', h)
                ctx.drawImage(img, 0, 0, w, h)
                canvas.toBlob((data) => {
                  img.data = new File([data], file.name, {type: data.type})
                  this.callback(img)
                }, 'image/jpeg', 0.5)
              }
            } else {
              img.data = file
              this.callback(img)
            }
          }
        } else {
          const reader = new FileReader()
          reader.readAsDataURL(file)
          if (file.size > 1048576) {
            this.$alert('文件大小不得超过1M', '提示');
            this.$refs.file.value = ''
          } else {
            reader.onload = (e) => {
              this.callback({src: e.target.result, data: file})
            }
          }
        }
      }
    }
  }
}
</script>

<style scoped>
.btn-file {
  display: flex;
  font-size: 12px;
  color: #777;
  letter-spacing: 0px;
  height: 30px;
  width: 120px;
  background-color: #303030;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.btn-file img {
  margin-right: 8px;
}

.file {
  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
}
</style>