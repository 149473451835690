<template>
  <div>
    <div class="form">
      <div class="close">
      <span class="icon" @click="close">
        <i class="el-icon-close"></i>
      </span>
      </div>
      <div class="title">
        <img src="/images/mine/edit-map.png">
        <div><span v-if="!readOnly">添加</span>特色地图
          <span v-if="showAutoSaving" style="font-size: 12px; color: #555; margin-left: 10px">自动保存中...</span>
        </div>
      </div>
      <div class="des">
      </div>
      <div>
        <div class="section">
          <div class="name">基本信息</div>
          <div class="section-des">设置特色地图标题和简介便于别人快速了解地图的内容。</div>
          <div class="block flex gray">
            <div class="label required">
              <div class="l1">地图标题：</div>
              <div class="l2">不超过12字</div>
            </div>
            <div style="width: 75%">
              <el-input v-model="form.title" maxlength="12" show-word-limit :disabled="!edit"/>
            </div>
          </div>
          <div class="block flex gray">
            <div class="label">
              <div class="l1">副标题：</div>
              <div class="l2">不超过30字</div>
            </div>
            <div style="width: 75%">
              <el-input type="textarea" v-model="form.subTitle" maxlength="30" show-word-limit :autosize="{ minRows: 2}"
                        :disabled="!edit"/>
            </div>
          </div>
          <div class="block flex gray">
            <div class="label">
              <div class="l1">地图简介：</div>
              <div class="l2">不超过200字</div>
            </div>
            <div style="width: 75%">
              <el-input type="textarea" v-model="form.des" maxlength="200" show-word-limit :autosize="{ minRows: 5}"
                        :disabled="!edit"/>
            </div>
          </div>
          <div class="block flex gray" style="padding-top: 0; padding-bottom: 0">
            <div class="label required">
              <div class="l1">封面图：</div>
              <div class="l2">宽度1024px</div>
            </div>
            <div class="upload-image">
              <img v-if="!coverUrl" src="/images/mine/form-pic.png">
              <img v-else :src="coverUrl">
            </div>
            <FileUploader v-if="edit" accept="image/jpeg,image/png" :callback="handleImageFile" ref="fileImage"/>
          </div>
          <div class="block flex gray" style="flex-wrap: wrap">
            <div class="label" style="width: 25%">
              <div class="l1">添加标签：</div>
              <div class="l2">用于搜索</div>
            </div>
            <div style="width: 75%">
              <el-input v-if="edit" v-model="tag" maxlength="6" show-word-limit>
                <el-button slot="append" @click="addTag">添加</el-button>
              </el-input>
            </div>
            <div>
              <el-tag style="margin-right: 10px; margin-top: 10px" v-for="(item,i) in form.tags" :key="i"
                      :closable="edit"
                      size="medium" @close="removeTag(i)">{{ item }}
              </el-tag>
            </div>
          </div>
          <div class="block">
            <div class="preview">
              <div class="img">
                <img v-if="coverUrl" :src="coverUrl">
                <div v-else style="color: white; padding: 10px; font-size: 12px">封面图预览</div>
              </div>
              <div class="text">
                <div class="title">{{ form.title }}</div>
                <div class="sub-title">{{ form.subTitle }}</div>
                <div class="creator">{{ user.uname }}</div>
                <div class="detail">详情</div>
              </div>
            </div>
          </div>
        </div>
        <div class="section">
          <div class="name">添加打点</div>
          <div class="section-des">可以添加打点，打点用于标注点位置，可用于导航目的地和途径点。</div>
          <div class="add-icon" v-if="edit">
            <i class="el-icon-circle-plus-outline" @click="openDialog(2)"></i>
          </div>
          <div v-if="edit">
            <div class="point-list" v-for="(item,i) in form.pointList" :key="i" style="position: relative">
              <img :src="item.img" class="opacity-edit">
              <div class="point-des">{{ item.des }}</div>
              <div style="position: absolute; top: -2px; right: -2px; font-size: 160%; color: #fff"
                   @click="deselectPoint(item)">
                <i class="el-icon-circle-close"></i>
              </div>
              <ColorSelect class="icon-color-point" :callback="changeColorPoint" :object.sync="item"/>
            </div>
          </div>
          <div v-else>
            <div @click="clickPoint(item.pid)" class="point-list" v-for="(item,i) in form.pointList" :key="i"
                 style="position: relative">
              <img :src="item.img">
              <div class="point-des">{{ item.des }}</div>
            </div>
          </div>
        </div>
        <div class="section">
          <div class="name">添加轨迹</div>
          <div class="section-des">可以添加轨迹，用于循迹和自驾时参考。可以添加多条轨迹，建议不超过20条。</div>
          <div class="add-icon" v-if="edit">
            <i title="上传本地轨迹" class="el-icon-upload2" @click="openUploadDialog" style="margin-right: 6px"></i>
            <i title="添加轨迹" class="el-icon-circle-plus-outline" @click="openDialog(1)"></i>
          </div>
          <div v-if="edit">
            <div style="position: relative" v-for="(item, i) in form.locusList" :key="i">
              <div class="locus-list">
                <LocusList :data="item" :show-bg="true"/>
              </div>
              <div class="icon-delete" @click="deselectLocus(item)"><i class="el-icon-delete"></i></div>
              <ColorSelect class="icon-color" :callback="changeColorLocus" :object.sync="item"/>
            </div>
          </div>
          <div v-else>
            <div style="position: relative" v-for="(item, i) in form.locusList" :key="i">
              <div class="locus-list">
                <LocusList :data="item" :show-bg="true"/>
              </div>
            </div>
          </div>
        </div>
        <div class="section">
          <div class="name">添加标注（见屏幕右下角）</div>
          <div class="section-des">在地图上可以添加文字和图形标注，画线标注可以用来表示路线，区域标注可以用来表示保护区、经济带等信息。</div>
        </div>
        <div class="section">
          <div class="name">设置中心点和默认层级</div>
          <div class="section-des">当前地图中心点为地图默认中心点位置，并缩放地图到合适层级。</div>
          <div class="block flex gray">
            <div class="label required">
              <div class="l1">中心点：</div>
              <div class="l2">移动地图设置</div>
            </div>
            <div style="width: 75%">
              <div class="label">{{ center.lat }}, {{ center.lng }}</div>
            </div>
          </div>
          <div class="block flex gray">
            <div class="label required">
              <div class="l1">默认层级：</div>
              <div class="l2">缩放地图设置</div>
            </div>
            <div style="width: 75%">
              <div class="label">{{ zoom }}</div>
            </div>
          </div>
        </div>
        <div v-if="!readOnly" class="btn-bar" style="padding-bottom: 20px">
          <el-button size="small" type="info" @click="save" v-if="edit">保存</el-button>
          <el-button size="small" type="info" @click="enableEdit" v-if="!edit">编辑</el-button>
          <el-button size="small" type="info" @click="remove" v-if="this.form.id">删除</el-button>
          <el-button size="small" type="info" @click="submit" v-if="!edit">提交审核</el-button>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible" width="80%" top="5vh">
      <div>
        <div v-if="showPointList">
          <el-tabs v-model="point.activeName" type="card" @tab-click="handleClickPointTab">
            <el-tab-pane label="我的打点" name="mine"></el-tab-pane>
            <el-tab-pane label="收藏的打点" name="favorite"></el-tab-pane>
          </el-tabs>
          <div style="height: 60vh; overflow-y: auto; margin-bottom: 15px">
            <Search v-if="point.activeName=='mine'" :callback="searchPoint" category="打点" class="search"
                    :clear="clearPoint"/>
            <div :class="[form.pointIds.indexOf(item.pid)>=0?'selected':'unselected']" class="point-list"
                 v-for="(item,i) in point.list" :key="i" @click="selectPoint(item)">
              <img :src="item.img">
              <div class="point-des">{{ item.des }}</div>
            </div>
          </div>
          <el-pagination background layout="prev, pager, next" :total="point.total" :page-size="point.limit"
                         @current-change="loadPointList" :current-page.sync="point.page"/>
        </div>
        <div v-if="showLocusList">
          <el-tabs v-model="locus.activeName" type="card" @tab-click="handleClickLocusTab">
            <el-tab-pane label="我的轨迹" name="mine"></el-tab-pane>
            <el-tab-pane label="收藏的轨迹" name="favorite"></el-tab-pane>
          </el-tabs>
          <div style="height: 60vh; overflow-y: auto; margin-bottom: 15px">
            <Search v-if="locus.activeName=='mine'" :callback="searchLocus" category="路线" class="search"
                    :clear="clearLocus"/>
            <div style="display: flex; flex-wrap: wrap">
              <div :class="[form.locusIds.indexOf(item.lid)>=0?'selected':'unselected']" class="locus-list"
                   v-for="(item, i) in locus.list" :key="i" @click="selectLocus(item)">
                <LocusList :data="item" :show-bg="true"/>
              </div>
            </div>
          </div>
          <el-pagination background layout="prev, pager, next" :total="locus.total" :page-size="locus.limit"
                         @current-change="loadLocusList" :current-page.sync="locus.page"/>
        </div>
      </div>
    </el-dialog>
    <el-dialog :title="pointInfo.name" :visible.sync="pointVisible" width="400px" top="5vh">
      <div class="point-info">
        <div>
          <img :src='pointInfo.img + "?imageView2/1/w/400"' width="100%" height="auto">
        </div>
        <div class="info">
          <div class="unit">
            {{ pointInfo.time|date }}<br>
            {{ pointInfo.time|time }}
          </div>
          <div class="unit">
            {{ pointInfo.evt }}m<br>
            海拔
          </div>
          <div class="unit">
            N{{ pointInfo.lat }}<br>
            E{{ pointInfo.lng }}
          </div>
        </div>
        <div style="margin-top: 20px; margin-left: 20px">
          <div style="display: flex; align-items: center">
            <img width="30px" height="30px" class="avatar" :src='pointInfo.uhead + "?imageView2/1/w/30"'>
            <div style="margin-left: 8px">{{ pointInfo.uname }}</div>
          </div>
        </div>
        <div v-if="pointInfo.htmlText" v-html="pointInfo.htmlText">
        </div>
        <div v-else class="des">
          {{ pointInfo.des }}
        </div>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="dialogUploadVisible" title="上传本地轨迹" width="600px">
      <div style="margin-bottom: 20px">目前支持GPX格式</div>
      <div>
        <el-form label-width="80px" label-position="left">
          <el-form-item label="轨迹名称">
            <el-input v-model="locusFile.name"></el-input>
          </el-form-item>
          <el-form-item label="轨迹文件">
            <el-button size="small" type="primary" @click="selectLocusFile">选择文件</el-button>
            <input type="file" ref="locusFile" style="display: none" @change="afterSelectLocusFile">
            <span style="margin-left: 10px; ">{{ locusFile.filename }}</span>
          </el-form-item>
          <div class="btn-bar">
            <el-button size="small" type="primary" @click="uploadLocusFile" :disabled="locusFile.disabled">
              立即上传<i v-if="!locusFile.loading" class="el-icon-upload el-icon--right"></i>
              <i v-else class="el-icon-loading el-icon--right"></i>
            </el-button>
          </div>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import FileUploader from "./FileUploader";
import {
  getLocusList,
  getPoints,
  USER,
  saveMap,
  deleteMap,
  submitMap,
  getPointInfo,
  uploadLocusFile,
  getLocusInfo
} from '@/api'
import LocusList from "../LocusList";
import ColorSelect from "./ColorSelect";
import PubSub from "pubsub-js";
import Search from "@/components/form/Search";

export default {
  name: "MapForm",
  components: {Search, ColorSelect, LocusList, FileUploader},
  props: ['defaultZoom', 'defaultCenter', 'data', 'readOnly'],
  data() {
    return {
      form: {
        id: null,
        title: '',
        subTitle: '',
        des: '',
        tags: [],
        locusIds: [],
        locusList: [],
        pointList: [],
        pointIds: [],
        status: 0,
      },
      tag: '',
      dialogVisible: false,
      dialogUploadVisible: false,
      showPointList: false,
      showLocusList: false,
      locus: {
        offset: 0,
        limit: 30,
        list: [],
        total: 0,
        search: '',
        page: 1,
        activeName: 'mine',
      },
      point: {
        offset: 0,
        limit: 100,
        list: [],
        total: 0,
        search: '',
        page: 1,
        activeName: 'mine',
      },
      coverUrl: null,
      coverBlob: null,
      user: {uname: ''},
      center: {lat: null, lng: null},
      zoom: null,
      geometry: {},
      edit: false,
      autoSaveInterval: null,
      showAutoSaving: false,
      pointVisible: false,
      pointInfo: {},
      locusFile: {
        name: '',
        filename: '',
        disabled: false,
        loading: false,
      }
    }
  },
  watch: {
    data() {
      this.initForm()
    },
    edit() {
      if (this.autoSaveInterval) {
        clearInterval(this.autoSaveInterval)
      }
      if (this.edit) {
        this.startInterval()
      }
    }
  },
  async mounted() {
    if (!this.readOnly) {
      this.initForm()
      await this.loadLocusList()
      await this.loadPointList()
      this.user = USER
      PubSub.subscribe('center_changed', (e, data) => {
        if (this.edit) {
          this.center = data
        }
      })
      PubSub.subscribe('zoom_changed', (e, data) => {
        if (this.edit) {
          this.zoom = data
        }
      })
      PubSub.subscribe('geometry_add', (e, data) => {
        let g = {}
        g.type = data.type
        if (data.content) {
          g.content = data.content
        }
        if (data.position) {
          const position = {lat: data.position.lat, lng: data.position.lng}
          g.position = {lat: position.lat.toFixed(6), lng: position.lng.toFixed(6)}
        }
        if (data.paths) {
          g.paths = data.paths
        }
        if (data.center) {
          const position = {lat: data.center.lat, lng: data.center.lng}
          g.position = {lat: position.lat.toFixed(6), lng: position.lng.toFixed(6)}
        }
        if (data.radius) {
          g.radius = data.radius.toFixed(2)
        }
        g.styleId = data.styleId
        this.geometry[data.id] = g
      })
      PubSub.subscribe('geometry_delete', (e, data) => {
        delete this.geometry[data[0].id]
      })
      PubSub.subscribe('geometry_update', (e, data) => {
        let g = this.geometry[data.id]
        if (data.content) {
          g.content = data.content
        }
        if (data.position) {
          const position = {lat: data.position.lat, lng: data.position.lng}
          g.position = {lat: position.lat.toFixed(6), lng: position.lng.toFixed(6)}
        }
        if (data.paths) {
          g.paths = data.paths
        }
        if (data.center) {
          const position = {lat: data.center.lat, lng: data.center.lng}
          g.position = {lat: position.lat.toFixed(6), lng: position.lng.toFixed(6)}
        }
        if (data.radius) {
          g.radius = data.radius.toFixed(2)
          delete g.paths
        }
        g.styleId = data.styleId
      })
    }
    PubSub.subscribe('map-click-point', (e, pid) => {
      this.showPointDialog(pid)
    })
  },
  destroyed() {
    if (this.autoSaveInterval) {
      clearInterval(this.autoSaveInterval)
    }
  },
  methods: {
    startInterval() {
      this.autoSaveInterval = setInterval(() => {
        if (this.form.title && (this.form.id || this.coverBlob)) {
          this.showAutoSaving = true
          this.saveMap(() => {
            setTimeout(() => {
              this.showAutoSaving = false
            }, 2000)
          })
        }
      }, 60000)
    },
    initForm() {
      if (this.data) {
        this.edit = false
        this.form.id = this.data.id
        this.form.des = this.data.des
        this.form.title = this.data.title
        this.form.subTitle = this.data.subTitle
        this.form.tags = []
        this.form.status = this.data.status
        if (this.data.tags) {
          this.form.tags = this.data.tags.split(',')
        }
        this.coverUrl = this.data.coverUrl
        this.form.pointList = []
        this.form.pointIds = []
        if (this.data.pointList) {
          this.form.pointList = this.data.pointList
          for (let i in this.data.pointList) {
            this.form.pointIds.push(this.data.pointList[i].pid)
          }
        }
        this.form.locusList = []
        this.form.locusIds = []
        if (this.data.locusList) {
          this.form.locusList = this.data.locusList
          for (let i in this.data.locusList) {
            this.form.locusIds.push(this.data.locusList[i].lid)
          }
        }
        this.center.lat = this.data.lat
        this.center.lng = this.data.lng
        this.zoom = this.data.zoom
        this.geometry = {}
        if (this.data.geometryList) {
          for (let i in this.data.geometryList) {
            const _g = this.data.geometryList[i]
            let g = {}
            g.type = _g.type
            if (_g.content) {
              g.content = _g.content
            }
            if (_g.position) {
              const position = {lat: _g.position.lat, lng: _g.position.lng}
              g.position = position
            }
            if (_g.paths) {
              g.paths = _g.paths
            }
            if (_g.center) {
              const position = {lat: _g.center.lat, lng: _g.center.lng}
              g.position = position
            }
            if (_g.radius) {
              g.radius = _g.radius
            }
            g.styleId = _g.styleId
            this.geometry[_g.id] = g
          }
        }
      } else {
        this.form = {
          id: null,
          title: '',
          subTitle: '',
          des: '',
          tags: [],
          locusIds: [],
          locusList: [],
          pointList: [],
          pointIds: [],
        }
        this.coverUrl = null
        this.coverBlob = null
        this.edit = true
        this.zoom = this.defaultZoom
        this.center = this.defaultCenter
      }
    },
    addTag() {
      if (this.tag && this.tag.trim()) {
        this.form.tags.push(this.tag)
        this.tag = ''
      }
    },
    removeTag(i) {
      this.form.tags.splice(i, 1)
    },
    openDialog(type) {
      this.dialogVisible = true
      if (type == 1) {
        this.showPointList = false
        this.showLocusList = true
      } else if (type == 2) {
        this.showLocusList = false
        this.showPointList = true
      }
    },
    openUploadDialog() {
      this.dialogUploadVisible = true
    },
    handleImageFile(img) {
      this.coverUrl = img.src
      this.coverBlob = img.data
    },
    searchLocus(text) {
      this.locus.search = text
      this.locus.page = 1
      this.loadLocusList(1)
    },
    clearLocus() {
      this.locus.search = ''
      this.locus.page = 1
      this.loadLocusList(1)
    },
    searchPoint(text) {
      this.point.search = text
      this.point.page = 1
      this.loadPointList(1)
    },
    clearPoint() {
      this.point.search = ''
      this.point.page = 1
      this.loadPointList(1)
    },
    handleClickPointTab(tab) {
      if (tab.name == 'mine') {
        this.locus.search = ''
        this.locus.page = 1
        this.loadPointList(1)
      } else {
        this.locus.search = ''
        this.locus.page = 1
        this.loadPointList(1)
      }
    },
    handleClickLocusTab(tab) {
      if (tab.name == 'mine') {
        this.locus.search = ''
        this.locus.page = 1
        this.loadLocusList(1)
      } else {
        this.locus.search = ''
        this.locus.page = 1
        this.loadLocusList(1)
      }
    },
    // 获取添加轨迹的列表
    async loadLocusList(page) {
      this.locus.list = []
      if (!page) {
        page = 1
      }
      this.locus.offset = (page - 1) * this.locus.limit
      let t = 1
      let uid = USER.uid
      if (this.locus.activeName == 'favorite') {
        t = 3
      } else if (this.locus.search) {
        // 可以搜索其他人的
        t = 2
        uid = null
      }
      await getLocusList(this.locus.offset, this.locus.limit, t, uid, this.locus.search, (data) => {
        let content = data.content
        for (let i in content) {
          content[i].colorIndex = 0
        }
        this.locus.list = content
        this.locus.total = data.locusNum
      })
    },
    // 获取添加打点的列表
    async loadPointList(page) {
      this.point.list = []
      if (!page) {
        page = 1
      }
      this.point.offset = (page - 1) * this.point.limit
      let uid = USER.uid
      let favorite = null
      if (this.point.activeName == 'favorite') {
        uid = null
        favorite = true
      } else if (this.point.search) {
        // 可以搜索其他人的
        uid = null
      }
      await getPoints(uid, this.point.search, this.point.offset, this.point.limit, 1, favorite, (data) => {
        let content = data.points
        for (let i in content) {
          content[i].colorIndex = 0
        }
        this.point.list = content
        this.point.total = data.pointCount
      })
    },
    selectLocus(locus) {
      if (this.form.locusIds.indexOf(locus.lid) < 0) {
        this.dialogVisible = false
        this.form.locusIds.push(locus.lid)
        this.form.locusList.push(locus)
        PubSub.publish('map-add-locus', locus)
      }
    },
    deselectLocus(locus) {
      const index = this.form.locusIds.indexOf(locus.lid)
      if (index >= 0) {
        PubSub.publish('map-remove-locus', locus.lid)
        this.form.locusIds.splice(index, 1)
        this.form.locusList.splice(index, 1)
      }
    },
    selectPoint(point) {
      if (this.form.pointIds.indexOf(point.pid) < 0) {
        this.dialogVisible = false
        this.form.pointIds.push(point.pid)
        this.form.pointList.push(point)
        PubSub.publish('map-add-point', point)
      }
    },
    deselectPoint(point) {
      const index = this.form.pointIds.indexOf(point.pid)
      if (index >= 0) {
        PubSub.publish('map-remove-point', point.pid)
        this.form.pointIds.splice(index, 1)
        this.form.pointList.splice(index, 1)
      }
    },
    changeColorLocus(locus) {
      PubSub.publish('change-color-locus', locus)
    },
    changeColorPoint(point) {
      PubSub.publish('change-color-point', point)
    },
    close() {
      PubSub.publish('edit-map-cancel')
      if (this.autoSaveInterval) {
        clearInterval(this.autoSaveInterval)
      }
    },
    enableEdit() {
      this.edit = true
      PubSub.publish('edit-map-start')
    },
    saveMap(callback) {
      let styles = {}
      let pointIds = this.form.pointIds
      let locusIds = this.form.locusIds
      for (let i in this.form.locusList) {
        let locus = this.form.locusList[i]
        styles['locus-' + locus.lid] = 'style-' + locus.colorIndex
      }
      for (let i in this.form.pointList) {
        let point = this.form.pointList[i]
        styles['point-' + point.pid] = 'style-' + point.colorIndex
      }
      let geometry = []
      for (let i in this.geometry) {
        geometry.push(this.geometry[i])
      }
      saveMap(this.form.id, this.form.title, this.form.subTitle, this.form.des, this.coverBlob, this.form.tags.join(), pointIds, locusIds, JSON.stringify(geometry),
          this.center.lat, this.center.lng, this.zoom, JSON.stringify(styles), (data) => {
            this.coverBlob = null
            if (!this.form.id) {
              data.coverUrl = 'https://img.map6.net/' + data.coverUrl + '?imageView2/1/w/200'
              PubSub.publish('add-map-success', data)
              this.form.id = data.id
            }
            callback()
          })
    },
    save() {
      if (!this.form.title) {
        this.$alert('请输入地图标题', '提示');
        return
      }
      if (!this.form.id && !this.coverBlob) {
        this.$alert('请添加封面图', '提示');
        return
      }
      this.$confirm('您将要保存此地图, 是否继续?', '提示', {
        closeOnClickModal: false,
        confirmButtonText: '保存',
        cancelButtonText: '取消',
        type: 'warning',
        beforeClose: (action, instance, done) => {
          if (action == 'confirm') {
            instance.confirmButtonLoading = true;
            this.saveMap(() => {
              instance.confirmButtonLoading = false
              done()
            })
          } else {
            done()
          }
        }
      }).then(() => {
        this.$message({
          type: 'success',
          message: '保存地图成功!'
        });
      })
    },
    remove() {
      this.$confirm('您将要删除此地图, 是否继续?', '提示', {
        closeOnClickModal: false,
        confirmButtonText: '删除',
        cancelButtonText: '取消',
        type: 'warning',
        beforeClose: (action, instance, done) => {
          if (action == 'confirm') {
            instance.confirmButtonLoading = true;
            deleteMap(this.form.id, () => {
              instance.confirmButtonLoading = false;
              PubSub.publish('delete-map', this.form.id)
              done()
            })
          } else {
            done()
          }
        }
      }).then(() => {
        this.$message({
          type: 'success',
          message: '删除地图成功!'
        });
      })
    },
    submit() {
      this.$confirm('您将要提交审核, 是否继续?', '提示', {
        closeOnClickModal: false,
        confirmButtonText: '提交审核',
        cancelButtonText: '取消',
        type: 'warning',
        beforeClose: (action, instance, done) => {
          if (action == 'confirm') {
            instance.confirmButtonLoading = true;
            submitMap(this.form.id, () => {
              instance.confirmButtonLoading = false;
              this.form.status = 10
              done()
            })
          } else {
            done()
          }
        }
      }).then(() => {
        this.$message({
          type: 'success',
          message: '提交审核成功!'
        });
      })
    },
    clickPoint(pid) {
      this.showPointDialog(pid)
    },
    showPointDialog(pid) {
      this.pointVisible = true
      getPointInfo(pid, 1, null, (data) => {
        this.pointInfo = data
      })
    },
    selectLocusFile() {
      this.$refs.locusFile.dispatchEvent(new MouseEvent("click"));
    },
    afterSelectLocusFile() {
      this.locusFile.file = this.$refs.locusFile.files[0];
      this.locusFile.filename = this.locusFile.file.name;
    },
    uploadLocusFile() {
      if (!this.locusFile.name) {
        this.$alert('请输入轨迹名称', '提示');
        return
      }
      if (!this.locusFile.filename) {
        this.$alert('请选择轨迹文件', '提示');
        return
      }
      if (!this.locusFile.filename.toLowerCase().endsWith('gpx')) {
        this.$alert('轨迹文件仅支持gpx格式', '提示');
        return
      }
      this.locusFile.loading = true
      this.locusFile.disabled = true
      uploadLocusFile(this.locusFile.name, this.locusFile.file, (data) => {
        getLocusInfo(data.lid, (locus) => {
          this.dialogUploadVisible = false
          locus.colorIndex = 0
          this.selectLocus(locus)
          this.locusFile.loading = false
          this.locusFile.disabled = false
        })
      })
    },
  }
}
</script>

<style scoped>
.form {
  color: #AAA;
  padding: 10px;
}

.title {
  display: flex;
  font-size: 24px;
  align-items: center;
}

.title img {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.des {
  margin-top: 10px;
  font-size: 12px;
  letter-spacing: 0px;
  color: #555;
  margin-bottom: 10px;
}

.form {
  counter-reset: section;
}

.section {
  padding: 5px 0;
  position: relative;
}

.section .name {
  font-weight: bold;
}

.section-des {
  font-size: 12px;
  letter-spacing: 1px;
  color: #555;
  margin-bottom: 10px;
}

.section .name::before {
  counter-increment: section;
  content: counter(section) ". ";
}

.block {
  margin-bottom: 10px;
  position: relative;
  padding: 10px 10px 10px 20px;
}

.block .required::after {
  content: "*";
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translate(0, -50%);
  color: #fb0;
}

.section .name {
  margin-bottom: 10px;
}

.label {
  font-size: 12px;
  color: #777;
}

.label .l2 {
  font-size: 12px;
  letter-spacing: 1px;
  transform: scale(0.8);
  margin-left: -11%;
  white-space: pre;
}

.gray {
  background-color: #202020;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex .inline {
  display: inline-block;
  width: 110px;
  margin-left: 10px;
}

.btn {
  background-color: #0ba;
  width: 102px;
  height: 24px;
  border-radius: 12px;
  font-size: 14px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 10px;
}

.upload-image img {
  width: 80px;
  height: 80px;
}

.add-icon {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 160%;
  cursor: pointer;
}

.locus-list {
  width: 340px;
  margin-right: 10px;
  margin-bottom: 10px;
  position: relative;
  cursor: pointer;
}

.icon-delete {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 150%;
}

.icon-color {
  position: absolute;
  right: 2px;
  bottom: 0;
  cursor: pointer;
}

.icon-color-point {
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: pointer;
}

.locus-list::after {
  content: '';
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}

.unselected {
  border: 3px solid white;
}

.selected {
  border: 3px solid #fb0;
}

.point-list {
  height: 80px;
  width: 80px;
  display: inline-block;
  margin-right: 6px;
  margin-bottom: 6px;
}

.point-list img {
  height: 80px;
  width: 80px;
}

.preview {
  display: flex;
  height: 100px;
  width: 100%;
  background-color: #4CA08D;
}

.preview .img {
  width: 100px;
  height: 100px;
}

.preview .img > img {
  width: 100px;
  height: 100px;
}

.preview .text {
  padding: 10px;
  color: #fff;
  font-size: 12px;
  position: relative;
  width: calc(100% - 100px);
}

.preview .text .title {
  font-size: 20px;
}

.preview .text .sub-title {
  white-space: pre;
}

.preview .text .creator {
  position: absolute;
  bottom: 10px;
  left: 10px;
}

.preview .text .detail {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.search {
  margin-bottom: 10px;
}

.opacity-edit:hover {
  opacity: 0.5;
}

.point-des {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 14px;
  line-height: 14px;
  margin-bottom: 10px;
  font-size: 12px;
  letter-spacing: 0;
}

.close {
  position: fixed;
  z-index: 2;
  text-align: right;
  width: 380px;
}

.close .icon {
  font-size: 120%;
  background-color: #202020;
  width: 26px;
  height: 26px;
  display: inline-block;
  text-align: center;
  border-radius: 13px;
  cursor: pointer;
  color: #C0C4CC;
}

.point-info {
  font-size: 12px;
}

.point-info .info {
  display: flex;
  justify-content: space-around;
  background-color: #000;
  color: #AAAAAA;
  padding: 10px 0;
  margin-top: -4px;
}

.point-info .info .unit {
  width: 33%;
  text-align: center
}

.point-info .avatar {
  border-radius: 15px;
}

.point-info .des {
  padding: 20px;
  white-space: pre-line;
  line-height: 150%;
}
</style>