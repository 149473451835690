<template>
  <div>
    <textarea id="editor"></textarea>
  </div>
</template>

<script>
import tinymce from 'tinymce/tinymce'
import 'tinymce/themes/silver'
import 'tinymce/models/dom'
import 'tinymce/icons/default'
import 'tinymce/plugins/table'
import 'tinymce/plugins/image'

export default {
  name: "RichText",
  data() {
    return {
      editorInit: {
        menubar: false,
        statusbar: false,
        language_url: '/tinymce/langs/zh_CN.js',
        language: 'zh_CN',
        skin_url: '/tinymce/skins/ui/oxide-dark',
        selector: '#editor',
        plugins: 'table image',
        toolbar: [
          'bold italic underline strikethrough forecolor backcolor hr image',
          'fontsize alignleft aligncenter alignright table',
        ],
        content_style: 'img {max-width:100%;height:auto;}',
        images_upload_handler: function (blobInfo) {
          return new Promise((resolve) => {
            const img = new Image()
            let reader = new FileReader()
            let file = blobInfo.blob()
            reader.readAsDataURL(file)
            reader.onload = function () {
              img.src = this.result
              if (file.size > 323000) {
                const ratio = Math.sqrt(file.size / 323000) * 0.8
                img.onload = () => {
                  const _w = img.width
                  const _h = img.height
                  const w = Math.round(_w / ratio)
                  const h = Math.round(_h / ratio)
                  const canvas = document.createElement('canvas')
                  let ctx = canvas.getContext('2d')
                  canvas.setAttribute('width', w)
                  canvas.setAttribute('height', h)
                  ctx.drawImage(img, 0, 0, w, h)
                  resolve(canvas.toDataURL('image/jpeg', 0.5))
                }
              } else {
                resolve(this.result)
              }
            }
          })
        },
      },
    }
  },
  mounted() {
    tinymce.init(this.editorInit)
  },
  methods: {
    setHtmlText(html) {
      tinymce.activeEditor.setContent(html)
    },
    getHtmlText() {
      return tinymce.activeEditor.getContent()
    }
  }
}
</script>

<style scoped>

</style>