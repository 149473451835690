export const getImage = (tab) => {
    let result = []
    const prefix = 'https://img.map6.net/map-img/' + tab + '-'
    if (tab == 'traffic') {
        for (let i = 1; i <= 90; i++) {
            if (i != 37 && i != 82 && i != 84) {
                let code = '' + i
                if (i < 10) {
                    code = '0' + code
                }
                result.push(prefix + code + '.png?imageView2/1/w/')
            }
        }
    } else if (tab == 'food') {
        for (let i = 1; i <= 12; i++) {
            let code = '' + i
            if (i < 10) {
                code = '0' + code
            }
            result.push(prefix + code + '.png?imageView2/1/w/')
        }
    } else if (tab == 'animal') {
        for (let i = 1; i <= 22; i++) {
            let code = '' + i
            if (i < 10) {
                code = '0' + code
            }
            result.push(prefix + code + '.png?imageView2/1/w/')
        }
    } else if (tab == 'weather') {
        for (let i = 1; i <= 18; i++) {
            let code = '' + i
            if (i < 10) {
                code = '0' + code
            }
            result.push(prefix + code + '.png?imageView2/1/w/')
        }
    } else if (tab == 'travel') {
        for (let i = 1; i <= 100; i++) {
            if (i != 37 && i != 82 && i != 84 && i != 92) {
                let code = '' + i
                if (i < 10) {
                    code = '0' + code
                }
                result.push(prefix + code + '.png?imageView2/1/w/')
            }
        }
    } else if (tab == 'resort') {
        for (let i = 1; i <= 100; i++) {
            let code = '' + i
            if (i < 10) {
                code = '0' + code
            }
            result.push(prefix + code + '.png?imageView2/1/w/')
        }
        for (let i = 201; i <= 281; i++) {
            let code = '' + i
            result.push(prefix + code + '.png?imageView2/1/w/')
        }
        for (let i = 300; i <= 399; i++) {
            let code = '' + i
            result.push(prefix + code + '.png?imageView2/1/w/')
        }
        for (let i = 401; i <= 421; i++) {
            let code = '' + i
            result.push(prefix + code + '.png?imageView2/1/w/')
        }
    } else if (tab == 'offroad') {
        for (let i = 1; i <= 100; i++) {
            let code = '' + i
            if (i < 10) {
                code = '0' + code
            }
            result.push(prefix + code + '.png?imageView2/1/w/')
        }
        for (let i = 201; i <= 216; i++) {
            let code = '' + i
            result.push(prefix + code + '.png?imageView2/1/w/')
        }
    }
    return result
}