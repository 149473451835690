<template>
  <div class="form">
    <div class="control" v-if="showControl">
      <div style="color:#0ba; font-size: 90%; margin-left: 8px">
        <!--        <img src="/images/locus/link.png" width="70px">-->
        <!--        <img src="/images/locus/share.png" width="70px">-->
      </div>
      <div>
        <img src="/images/locus/close.png" width="20px" @click="close">
      </div>
    </div>
    <div class="point" :class="{'active': active, 'inactive': !active}"
         @click="focusPoint(index)">
      <div class="name" v-if="data.title">{{ data.title }}</div>
      <div class="name" v-else-if="data.name">{{ data.name }}</div>
      <img v-if="imgFile" :src="imgFile" style="margin: 4px 0 4px 4px; width: calc(100% - 8px)">
      <img v-else-if="data.img" :src="data.img + '?imageView2/2/w/400'"
           style="margin: 4px 0 4px 4px; width: calc(100% - 8px)">
      <div v-if="data.audio" style="display: flex; margin: 4px 0 10px 4px;">
        <img v-if="!audioPlaying" src="/images/locus/yuyin.png" class="audio" @click="play">
        <img v-else src="/images/locus/yuyin.gif" class="audio" @click="play">
        <span class="audio-text" v-if="data.audioSec">{{ data.audioSec }} 秒</span>
      </div>
      <div v-else style="margin: 4px 0 10px 4px; height: 30px">
      </div>
      <div class="compact text" style="position: relative; user-select: none">
        <div
            style="display: flex; align-items: center; justify-content: right; position: absolute; right: 0; top: -50px">
          <div v-if="data.vrUrl" class="showImage" @click="showVr(data.vrUrl)">查看VR</div>
          <div class="showImage" @click="showImage(data.img)">查看大图</div>
          <div v-if="showLikeLoading" class="like-loading" v-loading="true" element-loading-spinner="el-icon-loading"
               element-loading-background="#000"></div>
          <div v-else style="height: 50px; width: 50px">
            <img v-if="!data.like" class="like" src="/images/locus/likeoff.png" @click="like(data.pid)">
            <img v-else class="like" src="/images/locus/likeon.png" @click="like(data.pid)">
          </div>
        </div>
        <img src="/images/locus/watch@2x.png" height="12px" style="margin-right: 4px; vertical-align: -2px">
        <span>{{ data.time|dateTime }} ● {{ data.likeCount }}次赞 ● {{
            data.commentCount ? data.commentCount : 0
          }}评论</span><span v-if="data.dis"> ● 里程：{{ (data.dis / 1000).toFixed(2) }}km</span>
      </div>
      <div v-show="showEdit">
        <div class="edit-tools">
          <div class="edit-tool" @click="changePointImage">
            <i class="el-icon-picture-outline"></i>
            <span>更换图片</span>
            <FileUploader accept="image/jpeg,image/png" :callback="handleImageFile" ref="pointImage"
                          style="display: none"></FileUploader>
          </div>
          <!--          <div class="edit-tool">-->
          <!--            <i class="el-icon-microphone"></i>-->
          <!--            <span>更换声音</span>-->
          <!--          </div>-->
          <!--          <div class="edit-tool">-->
          <!--            <i class="el-icon-turn-off-microphone"></i>-->
          <!--            <span>清除声音</span>-->
          <!--          </div>-->
          <div class="edit-tool" @click="deletePoint">
            <i class="el-icon-delete"></i>
            <span>删除打点</span>
          </div>
        </div>
        <div style="padding: 0 8px; margin-top: 20px">
          <div class="label">打点概况（纯文字）：</div>
          <div style="margin: 10px 0;">
            <el-input type="textarea" :autosize="{minRows: 8}" v-model="des" maxlength="800" show-word-limit></el-input>
          </div>
          <div class="label">图文打点标题：</div>
          <div style="margin: 10px 0;">
            <el-input v-model="title" maxlength="20"></el-input>
          </div>
          <div v-show="title">
            <div class="label">图文打点详情：</div>
            <RichText style="margin: 10px 0;" ref="richTextEditor"/>
          </div>
          <div class="label">VR全景url：</div>
          <div style="margin: 10px 0;">
            <el-input v-model="vrUrl"></el-input>
          </div>
          <div class="btn-bar">
            <el-button size="small" type="info" @click="cancelUpdate">取消</el-button>
            <el-button size="small" type="info" @click="updatePoint">保存</el-button>
          </div>
        </div>
      </div>
      <div v-show="!showEdit">
        <div class="des text">{{ data.des }}</div>
        <div v-if="data.htmlText" class="rich-text">
          <div v-html="data.htmlText"></div>
        </div>
        <div class="liker" v-if="data.likeUsers && data.likeUsers.length>0">
          <div>
            <img src="/images/locus/likeoff.png" width="24px">
          </div>
          <div>
            <div class="likerUser" v-for="(liker, j) in data.likeUsers" :key="'liker-' + index + '-' +j">
              <img :src="liker.head" width="25px" height="25px" :title="liker.uname">
            </div>
          </div>
        </div>
        <div class="comment">
          <div style="text-align: right; padding-right: 8px">
            <!--            <img src="/images/locus/comment@2x.png" width="54px" style="cursor:pointer;" @click="beforeShowComment">-->
            <LittleIconText v-if="!data.fav" icon="star-off" text="收藏" @click.native="addFav(data.pid)"/>
            <LittleIconText v-else icon="star-on" text="收藏" @click.native="delFav(data.pid)"/>
            <LittleIconText v-if="enableEdit && !showEdit" icon="edit-outline" text="编辑"
                            @click.native="beforeShowEdit"/>
            <LittleIconText icon="edit" text="写评论" @click.native="beforeShowComment"/>
          </div>
          <div class="compact" style="line-height: 18px" v-for="(comment, j) in data.comments"
               :key="'comment-' + index + '-' + j">
            <span class="user">{{ comment.uname }}:</span>
            <span class="comment">{{ comment.content }}</span>
            <span v-if="loginUser && loginUser.uid==comment.uid" class="delete"
                  @click="deleteComment(comment.id, j)">刪除</span>
          </div>
          <div class="text"></div>
          <div v-if="showComment" style="padding: 0 8px">
            <el-input type="textarea" :autosize="{minRows: 8}" v-model="comment"></el-input>
            <div class="btn-bar">
              <el-button size="small" type="info" @click="showComment=false">取消</el-button>
              <el-button size="small" type="info" @click="openConfirm">发布</el-button>
            </div>
          </div>
        </div>
      </div>
      <el-dialog :visible.sync="dialogVisible">
        <img width="100%" :src="dialogImageUrl + '?imageView2/2/w/800'" alt="">
      </el-dialog>
    </div>
  </div>
</template>

<script>
import PubSub from "pubsub-js";
import {
  like,
  addComment,
  deleteComment,
  deletePoint,
  updatePoint,
  ensureUserLogin,
  USER,
  addFav,
  delFav
} from '@/api'
import FileUploader from "./form/FileUploader";
import RichText from "./form/RichText";
import LittleIconText from "@/components/form/LittleIconText";

export default {
  name: "PointInfo",
  components: {LittleIconText, RichText, FileUploader},
  props: ['data', 'index', 'active', 'showControl', 'enableEdit'],
  data() {
    return {
      audioPlaying: false,
      audio: '',
      dialogVisible: false,
      dialogImageUrl: '',
      showComment: false,
      comment: '',
      showLikeLoading: false,
      loginUser: null,
      showEdit: false,
      des: '',
      imgFile: null,
      imgBlob: null,
      title: '',
      vrUrl: '',
    }
  },
  watch: {
    data() {
      this.showEdit = false
      this.showComment = false
    }
  },
  mounted() {
    this.loginUser = USER
    if (this.data.audio) {
      this.audio = new Audio()
      this.audio.src = this.data.audio
      this.audio.addEventListener('ended', () => {
        this.audioPlaying = false
      })
    }
  },
  methods: {
    focusPoint(index) {
      PubSub.publish('focus-point', {index})
    },
    play() {
      if (this.audioPlaying) {
        this.audio.pause()
        this.audioPlaying = false
      } else {
        this.audio.play()
        this.audioPlaying = true
      }
    },
    showImage(url) {
      this.dialogVisible = true
      this.dialogImageUrl = url
    },
    showVr(url) {
      window.open(url)
    },
    close() {
      PubSub.publish('close-locus')
    },
    async like(pid) {
      this.showLikeLoading = true
      await like(1, pid, (data) => {
        this.data.like = data.like
        if (data.like) {
          this.data.likeUsers.push({uname: data.uname, head: data.uhead, uid: data.uid})
        } else {
          const index = this.data.likeUsers.findIndex((item) => {
            return item.uid == data.uid
          })
          this.data.likeUsers.splice(index, 1)
        }
        this.showLikeLoading = false
      })
    },
    beforeShowComment() {
      ensureUserLogin(() => {
        this.showComment = true
      })
    },
    deleteComment(id, index) {
      deleteComment(2, id, () => {
        this.$message({
          type: 'success',
          message: '删除打点评论成功!'
        });
        this.data.comments.splice(index, 1)
      })
    },
    beforeShowEdit() {
      ensureUserLogin(() => {
        this.showEdit = true
        if (this.data.des) {
          this.des = this.data.des
        } else {
          this.des = ''
        }
        if (this.data.title) {
          this.title = this.data.title
        } else {
          this.title = ''
        }
        if (this.data.vrUrl) {
          this.vrUrl = this.data.vrUrl
        } else {
          this.vrUrl = ''
        }
        if (this.data.htmlText) {
          this.$refs.richTextEditor.setHtmlText(this.data.htmlText)
        } else {
          this.$refs.richTextEditor.setHtmlText('')
        }
      })
    },
    openConfirm() {
      this.$confirm('您将要发布对此打点的评论, 是否继续?', '提示', {
        closeOnClickModal: false,
        confirmButtonText: '发布',
        cancelButtonText: '取消',
        type: 'warning',
        beforeClose: (action, instance, done) => {
          if (action == 'confirm') {
            instance.confirmButtonLoading = true;
            addComment(this.comment, 2, this.data.pid, null, (data) => {
              data = data.comment
              this.data.comments.splice(0, 0, {uname: data.uname, content: data.content, id: data.id, uid: data.uid})
              instance.confirmButtonLoading = false
              this.showComment = false
              this.comment = ''
              done()
            })
          } else {
            done()
          }
        }
      }).then(() => {
        this.$message({
          type: 'success',
          message: '发布打点评论成功!'
        });
      })
    },
    changePointImage() {
      this.$refs.pointImage.open()
    },
    handleImageFile(img) {
      this.imgFile = img.src
      this.imgBlob = img.data
    },
    deletePoint() {
      this.$confirm('您将要删除此打点, 是否继续?', '提示', {
        confirmButtonText: '删除',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning',
        beforeClose: (action, instance, done) => {
          if (action == 'confirm') {
            instance.confirmButtonLoading = true
            deletePoint(this.data.pid, () => {
              instance.confirmButtonLoading = false
              done()
              PubSub.publish('delete-point', {pid: this.data.pid})
            })
          } else {
            done()
          }
        }
      }).then(() => {
        this.$message({
          type: 'success',
          message: '删除打点成功!'
        });
      })
    },
    cancelUpdate() {
      this.showEdit = false
      this.imgFile = null
      this.imgBlob = null
    },
    updatePoint() {
      this.$confirm('您将要更新此打点, 是否继续?', '提示', {
        confirmButtonText: '更新',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning',
        beforeClose: (action, instance, done) => {
          if (action == 'confirm') {
            instance.confirmButtonLoading = true
            updatePoint(this.data.pid, this.des, this.imgBlob, null, this.vrUrl, this.data.lat, this.data.lng, this.data.tags, this.title, this.$refs.richTextEditor.getHtmlText(),
                (data) => {
                  instance.confirmButtonLoading = false
                  done()
                  if (data.img) {
                    const img = 'http://img.map6.net/' + data.img
                    PubSub.publish('update-point', {pid: this.data.pid, img})
                    this.data.img = img
                    this.cancelUpdate()
                  }
                })
          } else {
            done()
          }
        }
      }).then(() => {
        this.$message({
          type: 'success',
          message: '更新打点成功!'
        });
      })
    },
    addFav(pid) {
      addFav(1, pid, (data) => {
        if(data.result) {
          this.data.fav = true
          this.$message({
            type: 'success',
            message: '收藏打点成功!'
          });
        }
      })
    },
    delFav(pid) {
      delFav(1, pid, (data) => {
        if(data.result) {
          this.data.fav = false
          this.$message({
            type: 'success',
            message: '取消收藏打点成功!'
          });
        }
      })
    }
  }
}
</script>

<style scoped>
.control {
  display: flex;
  justify-content: space-between;
  padding: 0px 10px 5px 0px;
}

.point {
  color: #777;
  font-size: 12px;
}

.point .like {
  cursor: pointer;
  width: 50px;
  height: 50px;
}

.point .like-loading {
  width: 50px;
  height: 50px;
  font-size: 200%;
}

.point .showImage {
  cursor: pointer;
  font-size: 14px;
  margin-right: 10px;
  margin-left: 10px;
  letter-spacing: 1px;
}


.point .liker {
  display: flex;
}

.point .likerUser {
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 2px;
  padding: 0;
}

.point .des {
  font-size: 15px;
  color: #bbb;
  letter-spacing: 1px;
}

.comment .user {
  color: #17b;
  margin-right: 10px;
}

.comment .delete {
  color: #17b;
  margin-left: 20px;
  cursor: pointer;
  letter-spacing: 1px;
}

.active {
  border: 3px solid #fb0;
}

.inactive {
  border: 3px solid #000;
}

.text {
  padding: 0 8px 10px 8px;
  white-space: pre-line;
  line-height: 150%;
}

.compact {
  letter-spacing: 0;
}

.audio {
  height: 30px;
  margin-right: 20px;
  cursor: pointer;
}

.audio-text {
  display: inline-block;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
}

.point .name {
  font-size: 20px;
  margin-bottom: 10px;
  padding-left: 8px;
}

.point .edit {
  position: absolute;
  right: 0;
  top: -4px;
  right: 12px;
  font-size: 200%;
  cursor: pointer;
}

.edit-tools {
  margin-top: 8px;
  display: flex;
}

.edit-tool {
  width: 25%;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.edit-tool > i {
  font-size: 150%;
  margin-right: 4px;
}

.rich-text {
  letter-spacing: 0;
  color: #fff;
  padding: 4px;
}
</style>