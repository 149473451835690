<template>
  <div class="form">
    <div class="title">
      <img src="/images/mine/edit-point.png">
      <div>添加打点</div>
    </div>
    <div class="des">
      可以添加打点，打点用于标注点位置，可用于导航目的地和途径点。 可先搜索你要添加打点的位置，并在地图上单击拾取经纬度坐标。 然后输入相应的打点内容。
    </div>
    <div>
      <div class="section">
        <div class="name">拾取位置</div>
        <div class="block" style="padding-bottom: 0; padding-top: 0">
          <div>
            <el-autocomplete placeholder="请输入地址，限中国境内" clearable v-model="addr" :fetch-suggestions="queryAddr"
                             @select="handleSelect"/>
          </div>
        </div>
        <div class="block flex gray">
          <div class="label required">
            <div class="l1">经纬度：</div>
            <div class="l2">可以从地图拾取</div>
          </div>
          <div>
            <el-input placeholder="请输入经度" v-model="lng" class="inline"/>
            <el-input placeholder="请输入纬度" v-model="lat" class="inline"/>
          </div>
        </div>
      </div>
      <div class="section">
        <div class="name">基本信息</div>
        <div class="block flex gray" style="padding-top: 0; padding-bottom: 0">
          <div class="label required">
            <div class="l1">打点图片：</div>
            <div class="l2">jpg/png</div>
          </div>
          <div class="upload-image">
            <img v-if="!imgFile" src="/images/mine/form-pic.png">
            <img v-else :src="imgFile" @click="showImage" style="object-fit: cover; cursor: pointer">
          </div>
          <FileUploader accept="image/jpeg,image/png" :callback="handleImageFile" ref="fileImage"/>
        </div>
        <div class="block flex gray" style="padding-top: 0; padding-bottom: 0">
          <div class="label">
            <div class="l1">打点语音：</div>
            <div class="l2">wav/mp3</div>
          </div>
          <div style="height: 40px; display: flex; align-items: center">
            <img v-if="!audioFile" src="/images/mine/form-audio.png" height="33px">
            <img v-if="audioFile && !audioPlay" src="/images/locus/yuyin.png" height="30px" @click="play">
            <img v-if="audioFile && audioPlay" src="/images/locus/yuyin.gif" height="30px" @click="pause">
          </div>
          <FileUploader accept="audio/mpeg,audio/x-wav" :callback="handleAudioFile" ref="fileAudio"/>
        </div>
        <div class="block flex gray" style="flex-wrap: wrap">
          <div class="label" style="width: 25%">
            <div class="l1">添加标签：</div>
            <div class="l2">用于搜索</div>
          </div>
          <div style="width: 75%">
            <el-input v-model="tag" maxlength="6" show-word-limit>
              <el-button slot="append" @click="addTag">添加</el-button>
            </el-input>
          </div>
          <div>
            <el-tag style="margin-right: 10px; margin-top: 10px" v-for="(item,i) in tags" :key="i" closable
                    size="medium" @close="removeTag(i)">{{ item }}
            </el-tag>
          </div>
        </div>
        <div class="block gray">
          <div class="label required" style="margin-bottom: 5px">
            <div class="l1">打点概况（纯文字）：</div>
            <div class="l2">不超过800字</div>
          </div>
          <div>
            <el-input type="textarea" :autosize="{ minRows: 8}" v-model="des" maxlength="800" show-word-limit/>
          </div>
        </div>
      </div>
      <div class="section">
        <div class="name">多图文/VR——让打点更精彩</div>
        <div class="des">
          用图文混排的形式详细介绍你的打点，还支持720YUN的VR全景链接，让打点身临其境，更吸引人前往。
        </div>
        <div class="block flex gray">
          <div class="label" style="width: 25%">
            <div class="l1">打点标题：</div>
            <div class="l2">不超过20字</div>
          </div>
          <div style="width: 75%">
            <el-input v-model="title" maxlength="20"/>
          </div>
        </div>
        <div v-show="title" class="block gray">
          <div class="label" style="margin-bottom: 5px">
            <div class="l1">打点详情（多图文）：</div>
            <div class="l2">支持多图文混排</div>
          </div>
          <div>
            <textarea id="editor-add"></textarea>
          </div>
        </div>
        <div class="block flex gray">
          <div class="label" style="width: 25%">
            <div class="l1">VR全景：</div>
            <div class="l2">支持720YUN</div>
          </div>
          <div style="width: 75%">
            <el-input v-model="vrUrl"/>
          </div>
        </div>
      </div>
      <div class="btn-bar" style="padding-bottom: 20px">
        <el-button size="small" type="info" @click="cancel">取消</el-button>
        <el-button size="small" type="info" @click="openConfirm">保存</el-button>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
import FileUploader from "./FileUploader"
import PubSub from "pubsub-js"
import {addPoint} from "@/api"
import tinymce from 'tinymce/tinymce'
import 'tinymce/themes/silver'
import 'tinymce/models/dom'
import 'tinymce/icons/default'
import 'tinymce/plugins/table'
import 'tinymce/plugins/image'

export default {
  name: "PointForm",
  components: {FileUploader},
  data() {
    return {
      addr: '',
      lat: '',
      lng: '',
      des: '',
      suggestion: null,
      imgFile: null,
      audioFile: null,
      dialogVisible: false,
      dialogImageUrl: '',
      audio: new Audio(),
      audioPlay: false,
      imgBlob: null,
      audioBlob: null,
      tag: '',
      tags: [],
      vrUrl: '',
      title: '',
      htmlText: '',
      editorInit: {
        menubar: false,
        statusbar: false,
        language_url: '/tinymce/langs/zh_CN.js',
        language: 'zh_CN',
        skin_url: '/tinymce/skins/ui/oxide-dark',
        selector: '#editor-add',
        plugins: 'table image',
        toolbar: [
          'bold italic underline strikethrough forecolor backcolor hr image',
          'fontsize alignleft aligncenter alignright table',
        ],
        content_style: 'img {max-width:100%;height:auto;}',
        images_upload_handler: function (blobInfo) {
          return new Promise((resolve) => {
            const img = new Image()
            let reader = new FileReader()
            let file = blobInfo.blob()
            reader.readAsDataURL(file)
            reader.onload = function () {
              img.src = this.result
              if (file.size > 323000) {
                const ratio = Math.sqrt(file.size / 323000) * 0.8
                img.onload = () => {
                  const _w = img.width
                  const _h = img.height
                  const w = Math.round(_w / ratio)
                  const h = Math.round(_h / ratio)
                  const canvas = document.createElement('canvas')
                  let ctx = canvas.getContext('2d')
                  canvas.setAttribute('width', w)
                  canvas.setAttribute('height', h)
                  ctx.drawImage(img, 0, 0, w, h)
                  resolve(canvas.toDataURL('image/jpeg', 0.5))
                }
              } else {
                resolve(this.result)
              }
            }
          })
        },
      },
    }
  },
  mounted() {
    tinymce.init(this.editorInit)
    this.suggestion = new window.TMap.service.Suggestion({
      pageSize: 20,
    })
    PubSub.subscribe('location-select', (e, data) => {
      this.lat = data.lat
      this.lng = data.lng
      PubSub.publish('add-point', data)
    })
  },
  methods: {
    queryAddr(queryString, cb) {
      if (queryString) {
        this.suggestion.getSuggestions({keyword: queryString}).then((data) => {
          let r = []
          if (data.data) {
            for (let i in data.data) {
              const rb = data.data[i]
              let value = rb.title
              if (rb.city) {
                value += ' ' + rb.city
              }
              r.push({value, address: rb.address, lat: rb.location.lat, lng: rb.location.lng})
            }
          }
          cb(r)
        })
      } else {
        cb([])
      }
    },
    handleSelect(e) {
      this.lat = e.lat
      this.lng = e.lng
      PubSub.publish('add-point', {lat: e.lat, lng: e.lng})
    },
    handleImageFile(img) {
      this.imgFile = img.src
      this.imgBlob = img.data
    },
    handleAudioFile(file) {
      this.audioFile = file.src
      this.audioBlob = file.data
      this.audio.src = file.src
    },
    showImage() {
      this.dialogImageUrl = this.imgFile;
      this.dialogVisible = true;
    },
    play() {
      this.audio.play()
      this.audioPlay = true
    },
    pause() {
      this.audio.pause()
      this.audioPlay = false
    },
    cancel() {
      this.addr = ''
      this.lat = ''
      this.lng = ''
      this.des = ''
      this.imgFile = null
      this.imgBlob = null
      this.audioFile = null
      this.audioBlob = null
      this.dialogImageUrl = ''
      this.audio.pause()
      PubSub.publish('edit-point-cancel')
      this.$refs.fileAudio.clear()
      this.$refs.fileImage.clear()
    },
    openConfirm() {
      if (!this.lat || !this.lng) {
        this.$alert('请选择打点位置', '提示');
      } else if (!this.imgBlob) {
        this.$alert('请上传打点图片', '提示');
      } else if (!this.des) {
        this.$alert('请输入打点概况', '提示');
      } else {
        this.$confirm('您将要保存此打点, 是否继续?', '提示', {
          closeOnClickModal: false,
          confirmButtonText: '保存',
          cancelButtonText: '取消',
          type: 'warning',
          beforeClose: (action, instance, done) => {
            if (action == 'confirm') {
              instance.confirmButtonLoading = true;
              addPoint(this.des, this.lng, this.lat, this.imgBlob, this.audioBlob, this.tags, this.vrUrl, this.title, tinymce.activeEditor.getContent(),
                  (data) => {
                    PubSub.publish('edit-point-success', data)
                    this.cancel()
                    instance.confirmButtonLoading = false
                    done()
                  })
              done()
            } else {
              done()
            }
          }
        }).then(() => {
          this.$message({
            type: 'success',
            message: '保存打点成功!'
          });
        })
      }
    },
    addTag() {
      if (this.tag && this.tag.trim()) {
        this.tags.push(this.tag)
        this.tag = ''
      }
    },
    removeTag(i) {
      this.tags.splice(i, 1)
    },
  },
}
</script>

<style scoped>
.form {
  color: #AAA;
  padding: 10px;
}

.title {
  display: flex;
  font-size: 24px;
  align-items: center;
}

.title img {
  width: 40px;
  margin-right: 10px;
}

.des {
  margin-top: 10px;
  font-size: 12px;
  letter-spacing: 0px;
  color: #555;
  margin-bottom: 10px;
}

.form {
  counter-reset: section;
}

.section {
  padding: 5px 0;
}

.section .name {
  font-weight: bold;
}

.section .name::before {
  counter-increment: section;
  content: counter(section) ". ";
}

.block {
  margin-bottom: 10px;
  position: relative;
  padding: 10px 10px 10px 20px;
}

.block .required::after {
  content: "*";
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translate(0, -50%);
  color: #fb0;
}

.section .name {
  margin-bottom: 10px;
}

.label {
  font-size: 12px;
  color: #777;
}

.label .l2 {
  font-size: 12px;
  letter-spacing: 1px;
  transform: scale(0.8);
  margin-left: -11%;
  white-space: pre;
}

.gray {
  background-color: #202020;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex .inline {
  display: inline-block;
  width: 110px;
  margin-left: 10px;
}

.btn {
  background-color: #0ba;
  width: 102px;
  height: 24px;
  border-radius: 12px;
  font-size: 14px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 10px;
}

.upload-image img {
  width: 80px;
  height: 80px;
}
</style>