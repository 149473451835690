<template>
  <div>
    <el-popover v-model="visible" trigger="click">
      <div class="color-panel">
        <div v-for="(item, i) in colors" class="color-block" @click="selectColor(i)" :key="i"
             :style="{backgroundColor: item}"></div>
      </div>
      <div slot="reference">
        <div class="color-block" :style="{backgroundColor: colors[object.colorIndex]}"></div>
      </div>
    </el-popover>
  </div>
</template>

<script>
import {getColors} from "../../api";

export default {
  name: "ColorSelect",
  props: ['callback', 'object'],
  data() {
    return {
      colors: [],
      visible: false
    }
  },
  mounted() {
    this.colors = getColors()
  },
  methods: {
    selectColor(i) {
      this.visible = false
      this.object.colorIndex = i
      this.$emit('update:object', this.object)
      this.callback(this.object)
    }
  }
}
</script>

<style scoped>
.color-panel {
  display: flex;
}

.color-block {
  width: 25px;
  height: 25px;
  border: 1px solid #ffffff;
}
</style>