<template>
  <div class="form">
    <div style="width: 200px">
      <el-input :placeholder="'搜索'+category+'...'" v-model="text" clearable @keyup.enter.native="search"
                @clear="clearInput"/>
    </div>
    <i class="el-icon-search" @click="search"></i>
  </div>
</template>

<script>
export default {
  name: "Search",
  data() {
    return {
      text: ''
    }
  },
  props: ["callback", 'category', 'clear'],
  methods: {
    search() {
      this.callback(this.text)
    },
    clearInput() {
      if (this.clear) {
        this.clear()
      }
    }
  }
}
</script>

<style scoped>
.form {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
}

.form i {
  color: #777;
  font-size: 150%;
  margin-left: 10px;
}
</style>