import {req, toLogin} from "./ajax";

const http2https = (obj, key) => {
    if (obj[key])
        obj[key] = obj[key].replace('http:', 'https:')
}

export const addPoint = (des, lng, lat, img, audio, tags, vrUrl, title, htmlText, callback) => {
    let data = {
        des, lng, lat, evt: 100, road: 0, time: 100, imgFile: img
    }
    if (audio) {
        data.audioFile = audio
    }
    if (vrUrl) {
        data.vrUrl = vrUrl
    }
    if (title) {
        data.title = title
    }
    if (tags && tags.length > 0) {
        data.tags = tags.join(',')
    }
    if (htmlText) {
        data.htmlText = htmlText
    }
    req('wpapi2/point/add', function (data) {
        http2https(data, 'img')
        callback(data)
    }, data, 'POST-FORM')
}

export const updatePoint = (pid, des, img, audio, vrUrl, lat, lng, tags, title, htmlText, callback) => {
    let data = {
        pid, des, lat, lng, vrUrl, title, htmlText, tags,
    }
    if (img) {
        data.imgFile = img
    }
    if (audio) {
        data.audioFile = audio
    }
    req('wpapi2/point/update', function (data) {
        callback(data)
    }, data, 'POST-FORM')
}

export const getNotes = (offset, limit, callback) => {
    req('wpapi2/note/getNotes', function (data) {
        let content = data.content
        for (let i in content) {
            http2https(content[i], 'img')
        }
        callback(data)
    }, {
        offset, limit
    })
}

export const getNoteInfo = (nid, callback) => {
    req('wpapi2/note/getNoteInfo', function (data) {
        http2https(data, 'img')
        http2https(data, 'thumbImg')
        callback(data)
    }, {
        nid
    })
}

export const getUsers = (uname, offset, limit, callback) => {
    let data = {offset, limit}
    if (uname) {
        data.uname = uname
    }
    req('wpapi2/user/getUsers', function (data) {
        let content = data.content
        for (let i in content) {
            http2https(content[i], 'head')
        }
        callback(data)
    }, data)
}

export const getLocusList = (offset, limit, t, uid, keyword, callback) => {
    let data = {
        offset, limit, t
    }
    if (keyword) {
        data.keyword = keyword
    }
    if (uid) {
        data.uid = uid
        data.scale = 1
        data.fromOFW = true
    }
    req('wpapi2/locus/getLocusList', function (data) {
        let content = data.content
        for (let i in content) {
            http2https(content[i], 'uhead')
            http2https(content[i], 'bgImg')
        }
        http2https(data, 'uhead')
        http2https(data, 'fpUrl')
        callback(data)
    }, data)
}

export const getPoints = (uid, keyword, offset, limit, scale, favorite, callback) => {
    req('wpapi2/point/getPoints', function (data) {
        let content = data.points
        for (let i in content) {
            http2https(content[i], 'img')
        }
        http2https(data, 'uhead')
        http2https(data, 'fpUrl')
        callback(data)
    }, {uid, keyword, offset, limit, scale, favorite})
}

export const deletePoint = (pid, callback) => {
    req('wpapi2/point/delete', function (data) {
        callback(data)
    }, {pid}, 'POST')
}

export const getLocusInfo = (lid, callback) => {
    req('wpapi2/locus/getLocusInfo', function (data) {
        http2https(data, 'bgImg')
        http2https(data, 'handmapUrl')
        http2https(data, 'uhead')
        http2https(data, 'partnerImg')
        http2https(data, 'key')
        let content = data.content
        for (let i in content) {
            http2https(content[i], 'img')
            http2https(content[i], 'uhead')
            let likers = content[i].likeUsers
            for (let j in likers) {
                http2https(likers[j], 'head')
            }
            let comments = content[i].comments
            for (let j in comments) {
                http2https(comments[j], 'uhead')
            }
        }
        callback(data)
    }, {
        lid,
        offset: 0,
        limit: 1000,
    })
}

export const getPointInfo = (pid, type, uid, callback) => {
    req('wpapi2/point/getPointInfo', function (data) {
        http2https(data, 'img')
        http2https(data, 'uhead')
        let likers = data.likeUsers
        for (let j in likers) {
            http2https(likers[j], 'head')
        }
        let comments = data.comments
        for (let j in comments) {
            http2https(comments[j], 'uhead')
        }
        callback(data)
    }, {
        pid, type, uid
    })
}

export const getComments = (type, refId, offset, limit, callback) => {
    req('wpapi2/comment/getComments', function (data) {
        callback(data)
    }, {
        type, refId, offset, limit
    })
}

export const getWaypoints = (lid, callback) => {
    req('resource/locus/waypoints/v1', function (data) {
        callback(data)
    }, {
        lid
    }, 'GET', true)
}

export const getConfig = (callback) => {
    req('api230/map/getConfig', function (data) {
        callback(data)
    }, {}, 'POST')
}

export const getUserInfo = (callback) => {
    req('wpapi2/user/getUserInfo', function (data) {
        http2https(data, 'uhead')
        callback(data)
    }, {})
}

// type=1 point, 点赞，取消点赞
export const like = (type, refId, callback) => {
    req('wpapi2/like/like', function (data) {
        callback(data)
    }, {type, refId}, 'POST')
}

// 收藏
export const addFav = (type, refId, callback) => {
    req('wpapi2/favorite/add', function (data) {
        callback(data)
    }, {type, refId}, 'POST')
}

export const delFav = (type, refId, callback) => {
    req('wpapi2/favorite/delete', function (data) {
        callback(data)
    }, {type, refId}, 'POST')
}

// type=1 locus, type=2 point
export const addComment = (content, type, refId, replyUid, callback) => {
    req('wpapi2/comment/add', function (data) {
        callback(data)
    }, {content, type, refId, replyUid}, 'POST')
}

export const deleteComment = (type, id, callback) => {
    req('wpapi2/comment/delete', function (data) {
        callback(data)
    }, {type, id}, 'POST')
}

export const saveMap = (id, title, subTitle, des, cover, tags, pointIds, locusIds, geometry, lat, lng, zoom, styles, callback) => {
    let data = {
        title, subTitle, des, tags, pointIds, locusIds, geometry, lat, lng, zoom, styles
    }
    if (id) {
        data.id = id
    }
    if (cover) {
        data.coverFile = cover
    }
    req('wpapi2/map/save', function (data) {
        callback(data)
    }, data, 'POST-FORM')
}

export const getMyMaps = (offset, limit, callback) => {
    req('wpapi2/map/list/mine', function (data) {
        callback(data)
    }, {offset, limit})
}

export const getMapInfo = (id, callback) => {
    req('wpapi2/map/info', function (data) {
        callback(data)
    }, {id})
}

export const getMapPubInfo = (id, callback) => {
    req('wpapi2/map/pub-info', function (data) {
        callback(data)
    }, {id})
}

export const deleteMap = (id, callback) => {
    req('wpapi2/map/delete', function (data) {
        callback(data)
    }, {id}, 'POST')
}

export const submitMap = (id, callback) => {
    req('wpapi2/map/submit', function (data) {
        callback(data)
    }, {id}, 'POST')
}

export const uploadLocusFile = (name, file, callback) => {
    req('wpapi2/locus/upload', function (data) {
        http2https(data, 'img')
        callback(data)
    }, {name, file}, 'POST-FORM')
}

export const getLocusLabel = (z, callback) => {
    req('wpapi2/locusLabel/query', function (data) {
        callback(data)
    }, {z})
}

export const logout = (callback) => {
    req('wpapi2/login/logout', function (data) {
        callback(data)
    }, {})
}

// 存储当前登录用户信息
export let USER = null

// 同步当前登录用户信息
export const syncLoginStatus = () => {
    getUserInfo((data) => {
        USER = data
    })
}

// 强制登录
export const ensureUserLogin = (cb) => {
    if (USER == null) {
        getUserInfo((data) => {
            if (!data || !data.uid) {
                toLogin()
            } else {
                USER = data
                cb(USER)
            }
        })
    } else {
        cb(USER)
    }
}

export const getColors = () => {
    return ['#F8B300', '#00B29F', '#EC4446', '#EEEA39', '#4BADE2', '#9863A2', '#000000']
}