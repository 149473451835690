<template>
  <div class="locus">
    <img v-if="!showBg" class="avatar" :src="data.uhead + '?imageView2/1/w/70'" @click="showUser(data.uid)">
    <img v-else class="avatar" :src="data.bgImg" @click="showLocus(data.lid, index)">
    <div class="text" @click="showLocus(data.lid, index)">
      <div class="title" :class="{'active': active}">{{ data.title }}</div>
      <div class="other">
        <div class="user">{{ data.uname }}</div>
        <div style="display: flex; justify-content: space-between">
          <div clsss="stat">打点 {{ data.markNum }} 浏览 {{ data.visited }} 赞 {{ data.likeNum }}</div>
          <div class="dis">{{ (data.dis / 1000).toFixed(2) }} km</div>
        </div>
      </div>
    </div>
    <img v-if="data.isRecommend==1" class="rec" src="/images/locus/recom@3x.png">
  </div>
</template>

<script>
import PubSub from "pubsub-js";

export default {
  name: "LocusList",
  props: ['data', 'active', 'index', 'showBg'],
  methods: {
    showLocus(lid, index) {
      PubSub.publish('show-locus', {lid, index})
    },
    showUser(uid) {
      window.location.href = '/user/' + uid
    }
  }
}
</script>

<style scoped>
.locus {
  width: 100%;
  height: 70px;
  background-color: #191919;
  position: relative;
  display: flex;
  cursor: pointer;
}

.locus .avatar {
  width: 70px;
  height: 70px;
  flex-grow: 0;
}

.locus .rec {
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
}

.locus .text {
  padding: 6px 8px;
  width: 254px;
  height: 58px;
}

.locus .title {
  overflow: hidden;
  font-size: 17px;
  color: #fff;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 4px;
}

.locus .other {
  color: #777;
  font-size: 12px;
  transform: scale(0.8);
  margin-left: -28px;
}

.locus .user {
  margin-bottom: 4px;
}

.locus .dis {
  text-align: right;
}

.locus .active {
  color: #fb0;
}
</style>