import Vue from 'vue'
import App from './App.vue'
import "../../assets/style/global.css";
import router from "./router";
import Moment from "moment";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI);
Vue.config.productionTip = false
Vue.filter('date', (value) => Moment(value).format('YYYY-MM-DD'))
Vue.filter('dateTime', (value) => Moment(value).format('YYYY-MM-DD HH:mm:ss'))
Vue.filter('time', (value) => Moment(value).format('HH:mm:ss'))

new Vue({
    render: h => h(App),
    router
}).$mount('#app')
